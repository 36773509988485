import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {baseUrl} from "../../../Hooks/Helper";

const initialState = {
  cartItems: [],
  loading: false,
  err: null,
  updates: false,
  flash_sale: null
};

export const fetchCartList = createAsyncThunk(
  "fetchCartList/cart",
  async ({ customerToken }) => {
    const isLogin = localStorage.getItem("isLogin");

    let url = '';
    if (customerToken && isLogin) {
      url = `${baseUrl.url}/user/cart-list`
    }
    else {
      url = `${baseUrl.url}/cart-list`;
    }
      const res = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: customerToken.length>20 ? `bearer ${customerToken}` : baseUrl.token,
        },
      });

    return await res.json();
  }
);

const cartListSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setUpdate: (state) => {
      state.updates = !state.updates;
    },
    updateCartItems: (state, action) => {
       state.cartItems = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCartList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCartList.fulfilled, (state, action) => {
      state.loading = false;
      state.cartItems = action.payload?.data;
      state.flash_sale = action.payload?.flash_sale
      state.err = null;
    });
    builder.addCase(fetchCartList.rejected, (state, action) => {
      state.loading = false;
      state.cartItems = [];
      state.err = action.payload;
    });
  },
});
export const { setUpdate, updateCartItems } = cartListSlice.actions;
export default cartListSlice.reducer;