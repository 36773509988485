import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { baseUrl} from "../../../Hooks/Helper";


const initialState = {
    wishlist: [],
    loading: true,
    err: null,
    updates: false,
}

export const fetchAllwishList = createAsyncThunk("fetchWishlist/wishlist", async({ customerToken }) =>{
    const res = await fetch(`${baseUrl.url}/user/wish-list`, {
        method: 'GET',
        headers: {
            Authorization: `bearer ${customerToken}`,
        },
    })
    
    if(res.ok&& res.status!==204){
      const resData = await res.json();
        return resData?.data
    }
    return []
})

const wishListSlice = createSlice({
    name: "wishlist",
    initialState,
    reducers: {
      setUpdate: (state) => {
        state.updateWishlist = !state.updateWishlist;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(fetchAllwishList.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchAllwishList.fulfilled, (state, action) => {
        state.loading = false;
        state.wishlist = action.payload;
        state.err = null;
      });
      builder.addCase(fetchAllwishList.rejected, (state, action) => {
        state.loading = false;
        state.wishlist = [];
        state.err = action.payload;
      });
    },
  });
export const { setUpdateWishlist } = wishListSlice.actions;
export default  wishListSlice.reducer;