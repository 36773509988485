import React, {useEffect, useState} from "react";
import {RxCross1} from "react-icons/rx";
import {BsCart3} from "react-icons/bs";
import {HiBars3BottomRight, HiOutlineUserCircle} from "react-icons/hi2";
import {Link, useLocation, useNavigate} from "react-router-dom";
import useStore from "../../../Hooks/useStore";
import "./NavBar2.css";
import {baseUrl, handleLogout, navItems} from "../../../Hooks/Helper";
import {useDispatch, useSelector} from "react-redux";
import {fetchCartList} from "../../../Redux/Slices/Cart/cartListSlice";
import {fetchAllwishList} from "../../../Redux/Slices/Wishlist/wishlistSlice";
import {IoMdHeartEmpty} from "react-icons/io";
import {VscGitCompare} from "react-icons/vsc";
import Button from "../../Button/Button";
import {FiPhoneCall} from "react-icons/fi";
import VisibleBranch from "../../VisibleBranch/VisibleBranch";

const NavBar2 = ({staticMenus, flash}) => {

    const navigate = useNavigate();
    const location = useLocation().pathname;

    //necessary states from context api
    const {
        customerToken,
        setting,
        setViewAllFlashSell,
        setDiscount,
        setFeatureCat,
        setSubCategoryId,
        setMinPrice,
        setRefreshAll,
        setMaxPrice,
        setCategoryId,
        setProductName,
        update,
        compare,
        refreshAll,
        customerInfo,
        darkMode,
        selectedBranch,
        setCustomerToken
    } = useStore().data;

    const {logo, dark_logo, phone} = setting;
    const [showNav2Menu, setShowNav2Menu] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [sticky, setSticky] = useState(false);
    const dispatch = useDispatch();
    const {cartItems, updates} = useSelector((state) => state?.cartItems);
    const {wishlist} = useSelector((state) => state?.wishlist);
    const verified = customerInfo?.user_data?.email_verified_at;
    const [visibleBranch, setVisibleBranch] = useState(false);


    //cartItems and wishlist UseEffect
    useEffect(() => {
        dispatch(fetchCartList({customerToken}));
    }, [dispatch, customerToken, update, updates]);

    useEffect(() => {
        if (!customerToken || !verified) {
            return;
        }
        if (customerToken) {
            dispatch(fetchAllwishList({customerToken}));
        }
    }, [dispatch, customerToken, update, verified]);

    //handle search functionality

    const handleSearchChange = (event) => {
        if (event.key === "Enter") {
            setProductName(searchTerm);
            navigate("/products");
        }
    };

    //sticky navbar
    useEffect(() => {
        window.addEventListener("scroll", () => {
            const scrolled = document.documentElement.scrollTop;
            if (scrolled > 300) {
                setSticky(true);
            } else if (scrolled <= 200) {
                setSticky(false);
            }
        });
    }, []);

    const branchModalData = {visibleBranch, setVisibleBranch}

    return (
        <div
            // className={`border-b dark:border-slate-700 dark:bg-slate-800 ${
            //     sticky ? "sticky-nav  bg-white" : ""
            // }`}
            className={`navBar dark:bg-gray-800 ${
                sticky ? "sticky-nav backdrop-blur-md bg-white/30" : ""
            }`}
        >
            <VisibleBranch branchModalData={branchModalData} />
            {!sticky && (
                <div className="bg-gray-100 dark:bg-gray-950">
                    <div className="container mb-1">
                        <div className="grid grid-cols-12 items-center">
                            <div className="col-span-5 sm:col-span-5 lg:col-span-4">
                                <ul className="flex justify-start items-center ">
                                    <li className="px-1 py-1 hidden lg:block border-r dark:border-gray-800 lg:px-3 cursor-pointer">
                                        <Link to={"#"} className="text-xs text-gray-600 dark:text-white">
                                            Help line
                                        </Link>
                                    </li>
                                    <li className="px-1 py-1 lg:px-3 cursor-pointer">
                                        <Link to={"#"}
                                              className="text-xs text-gray-600 dark:text-white flex items-center">
                                            <FiPhoneCall className="mr-2 flex lg:hidden"/>
                                            {phone}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-span-7 sm:col-span-7 lg:col-span-8">
                                <ul className="flex justify-end items-center">
                                    <li onClick={() => setVisibleBranch(true)}
                                        className="px-1  border-r dark:border-gray-800 lg:px-3 cursor-pointer">
                                        <p
                                            className="text-xs text-gray-600 dark:text-white"
                                        >
                                            {selectedBranch?.name}
                                        </p>
                                    </li>
                                    {
                                        customerToken && customerToken.length > 20 ? (
                                                <li className="pl-1 py-1 lg:px-3">
                                                    <button
                                                        onClick={() => handleLogout(customerToken, setCustomerToken, navigate())}
                                                        className="text-xs text-gray-600 dark:text-white"
                                                    >
                                                        Logout
                                                    </button>
                                                </li>
                                            )
                                            :
                                            <>
                                                <li className="px-1  border-r lg:px-3 cursor-pointer">
                                                    <Link
                                                        className="text-xs text-gray-600 dark:text-white"
                                                        to={"/register"}
                                                    >
                                                        Registration
                                                    </Link>
                                                </li>
                                                <li className="pl-1 py-1 lg:px-3">
                                                    <Link
                                                        to={"/login"}
                                                        className="text-xs text-gray-600 dark:text-white"
                                                    >
                                                        Login
                                                    </Link>
                                                </li>
                                            </>
                                    }

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="container mb-1">
                <div className="grid grid-cols-12 items-center">
                    <div className="col-span-3 md:col-span-6 lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                        <div
                            onClick={() => {
                                navigate("/");
                            }}
                            className="block w-40 h-16"
                        >
                            {dark_logo || logo ? (
                                <img
                                    className={" h-full w-auto"}
                                    src={
                                        darkMode
                                            ? `${baseUrl.img}${dark_logo}`
                                            : `${baseUrl.img}${logo}`
                                    }
                                    alt={"website logo"}
                                />
                            ) : (
                                <img
                                    className={" h-full w-auto"}
                                    src="https://placehold.co/160x64"
                                    alt={"website logo"}
                                />
                            )}
                        </div>
                    </div>
                    <div className="col-span-9 md:col-span-6 lg:col-span-6 xl:col-span-5 2xl:col-span-6 ">
                        <div
                            onClick={() => setShowNav2Menu(!showNav2Menu)}
                            className={`sm:block lg:hidden`}
                        >
                            <div className="flex justify-end">
                                {!showNav2Menu ? (
                                    <HiBars3BottomRight
                                        size={20}
                                        className="dark:text-white cursor-pointer"
                                    />
                                ) : (
                                    <RxCross1 size={20} className="dark:text-red-400"/>
                                )}
                            </div>
                        </div>
                        <div
                            className={`absolute z-50   dark:bg-slate-800 lg:bg-transparent rounded-lg left-[4%] w-[92%] lg:w-fit p-4 lg:relative ${
                                showNav2Menu
                                    ? "top-28 lg:top-0 bg-white shadow-xl"
                                    : "-top-full"
                            }`}
                        >
                            <ul
                                className={
                                    "m-0 p-0 sm:block md:flex lg:flex items-center roboto"
                                }
                            >
                                {navItems?.map((item, i) => {
                                    return(
                                        <li key={i} className="mr-5">
                                            <button
                                                className={`border-none flex items-center ${
                                                    item.route === location
                                                        ? "active-nav-menu "
                                                        : "bg-transparent"
                                                }`}
                                                onClick={() => {
                                                    navigate(item.route);
                                                    setViewAllFlashSell(0);
                                                    setDiscount(0);
                                                    setFeatureCat(0);
                                                    setSubCategoryId(null);
                                                    setMinPrice(null);
                                                    setRefreshAll(!refreshAll);
                                                    setMaxPrice(null);
                                                    setCategoryId("");
                                                    setProductName("");
                                                    setShowNav2Menu(false);
                                                }}
                                            >
                                                {item?.icon}{" "}
                                                <span className="ml-2 dark:text-white whitespace-nowrap">
                                                {item.page}
                                            </span>
                                            </button>
                                        </li>
                                    )
                                })}
                                {staticMenus?.map((item, i) => {
                                    if (item?.static_menu_type?.name === "Footer") {
                                        return null;
                                    }
                                    return (
                                        <li key={i} className="mr-5">
                                            <button
                                                className={`border-none flex items-center ${
                                                    item.route === location
                                                        ? "active-nav-menu "
                                                        : "bg-transparent"
                                                }`}
                                                onClick={() => {
                                                    navigate(`/page/${item.id}`);
                                                    setViewAllFlashSell(0);
                                                    setDiscount(0);
                                                    setFeatureCat(0);
                                                    setSubCategoryId(null);
                                                    setMinPrice(null);
                                                    setRefreshAll(!refreshAll);
                                                    setMaxPrice(null);
                                                    setCategoryId("");
                                                    setProductName("");
                                                    setShowNav2Menu(false);
                                                }}
                                            >
                                                {item?.icon}{" "}
                                                <span className="ml-2 dark:text-white whitespace-nowrap">
                                                    {item.menu_name}
                                                </span>
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-12 lg:col-span-4 xl:col-span-5 2xl:col-span-4">
                        <div className="flex items-center justify-between lg:justify-end  ">
                            <div className="" onClick={() => setShowNav2Menu(false)}>
                                <div onKeyDown={handleSearchChange} className="">
                                    <input
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        placeholder="Search products"
                                        type="text"
                                        className="px-3 dark:text-white py-2 w-full focus:outline-none bg-transparent border border-1 border-gray-300"
                                    />
                                </div>
                            </div>
                            <div className="">
                                <div className="flex items-center gap-4">
                                    <ul className="flex items-center p-0 m-0 nav-icons-group">
                                        <li className="ml-2 relative cart">
                                            {customerToken && customerToken.length > 20 ? (
                                                <div
                                                    className="rounded-ful flex items-center justify-center w-8 h-8 bg-teal-500 rounded-full">
                                                    {
                                                        <Link
                                                            className="relative"
                                                            to={"/wishlist"}
                                                            onClick={() => setShowNav2Menu(false)}
                                                        >
                                                            <IoMdHeartEmpty
                                                                className="text-white"
                                                                size={19}
                                                            />
                                                            {wishlist?.length > 0 && (
                                                                <div
                                                                    className="absolute -top-4  left-3 bg-red-400 w-4 h-4 text-center text-xs rounded-full text-white">
                                                                    {wishlist?.length}
                                                                </div>
                                                            )}
                                                        </Link>
                                                    }
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </li>

                                        <li className="ml-2 relative cart">
                                            <div
                                                className="rounded-ful flex items-center justify-center w-8 h-8 bg-yellow-500 rounded-full">
                                                <Link
                                                    className="relative"
                                                    to={"/compare"}
                                                    onClick={() => setShowNav2Menu(false)}
                                                >
                                                    <VscGitCompare className="text-white" size={19}/>
                                                    {compare?.length > 0 ? (
                                                        <div
                                                            className="absolute -top-4  left-3 bg-red-400 w-4 h-4 text-center text-xs rounded-full text-white">
                                                            {compare?.length}
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Link>
                                            </div>
                                        </li>

                                        <li className="ml-2 relative cart">
                                            <div
                                                className="rounded-ful flex items-center justify-center w-8 h-8 bg-blue-500 rounded-full">
                                                <Link
                                                    className="relative"
                                                    to={"/cart"}
                                                    onClick={() => setShowNav2Menu(false)}
                                                >
                                                    <BsCart3 className="text-white" size={19}/>
                                                    {cartItems?.length > 0 && (
                                                        <div
                                                            className="absolute -top-4  left-3 bg-red-400 w-4 h-4 text-center text-xs rounded-full text-white">
                                                            {cartItems?.length}
                                                        </div>
                                                    )}
                                                </Link>
                                                <div className="cart-hover-item bg-white dark:bg-slate-800">
                                                    {cartItems?.length > 0 ? (
                                                        <div>
                                                            {cartItems?.slice(0, 5)?.map((cart, i) => (
                                                                <div key={i} className="grid grid-cols-12">
                                                                    <div className="col-span-3">
                                                                        <img
                                                                            className="dark:bg-slate-900 rounded-lg"
                                                                            style={{height: "50px", width: "50px"}}
                                                                            src={`${baseUrl.img}${cart?.product_combination?.product?.thumbnail_image}`}
                                                                            alt="cart"
                                                                        />
                                                                    </div>
                                                                    <div className="col-span-9">
                                                                        <p
                                                                            style={{lineHeight: "15px"}}
                                                                            className="p-0 m-0 fw-bold font-medium dark:text-white"
                                                                        >
                                                                            {cart?.product_combination?.product?.name}
                                                                        </p>
                                                                        <p className="dark:text-white">
                                                                            {cart?.product_quantity} X ৳
                                                                            {flash &&
                                                                            cart?.product_combination?.product
                                                                                ?.is_on_sale === 1 ? (
                                                                                <span>
                                                                                  {(
                                                                                      cart?.product_combination
                                                                                          ?.selling_price -
                                                                                      (cart?.product_combination
                                                                                              ?.selling_price *
                                                                                          flash.discount) /
                                                                                      100
                                                                                  ).toFixed(2)}
                                                                                </span>
                                                                            ) : (
                                                                                <span>
                                                                                  {
                                                                                      cart?.product_combination
                                                                                          ?.selling_price
                                                                                  }
                                                                                </span>
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        <div className="p-3 text-center">
                                                            <div
                                                                style={{width: "60px"}}
                                                                className="mx-auto"
                                                            >
                                                                <img
                                                                    src={require("../../../assets/cart/no-item.png")}
                                                                    alt=""
                                                                    className="w-full"
                                                                />
                                                            </div>
                                                            <p className="dark:text-white">No item in cart</p>
                                                        </div>
                                                    )}
                                                    <div>
                                                        <Link
                                                            className="block"
                                                            to={"/cart"}
                                                            onClick={() => setShowNav2Menu(false)}
                                                        >
                                                            <Button
                                                                classes={"w-full"}
                                                                size={"md"}
                                                                title={"View Cart"}
                                                            />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ml-1 rounded-full text-dark flex items-center justify-center w-8 h-8">
                                            {customerToken && customerToken.length > 20 ? (
                                                <Link
                                                    to={"/profile"}
                                                    className="text-decoration-none flex items-center justify-center"
                                                >
                                                    {
                                                        customerInfo?.user_data?.profile?.image ? <img
                                                                style={{height: "32px", width: "32px"}}
                                                                className="rounded-full overflow-hidden"
                                                                src={
                                                                    `${baseUrl.img}${customerInfo?.user_data?.profile?.image}`
                                                                }
                                                                alt=""
                                                            />
                                                            :
                                                            <img
                                                                className="rounded-full overflow-hidden h-full w-full border"
                                                                src={require("../../../assets/avatar/avatar.jpg")}
                                                                alt=""
                                                            />
                                                    }

                                                    {/*<img*/}
                                                    {/*    style={{height: "32px", width: "32px"}}*/}
                                                    {/*    className="rounded-full overflow-hidden"*/}
                                                    {/*    src={*/}
                                                    {/*        `${baseUrl.img}${customerInfo?.user_data?.profile?.image}` ||*/}
                                                    {/*    }*/}
                                                    {/*    alt=""*/}
                                                    {/*/>*/}
                                                </Link>
                                            ) : (
                                                <Link
                                                    to={"/profile"}
                                                    onClick={() => setShowNav2Menu(false)}
                                                >
                                                    <div
                                                        className="rounded-ful flex items-center justify-center w-8 h-8 bg-red-400 rounded-full">
                                                        <HiOutlineUserCircle
                                                            className="text-white"
                                                            size={21}
                                                        />
                                                    </div>
                                                </Link>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavBar2;
