import React from 'react';
import WishlistContainer from '../../Components/WishlistContainer/WishlistContainer';
import PageBanner from '../../Components/PageBanner/PageBanner';
import Subscription2 from '../../Components/Subscriptions/Subscription2/Subscription2';
const WishlistPage = () => {

    return (
        <>
            <PageBanner/>
            <WishlistContainer/>
            <Subscription2/>
        </>
    );
};

export default WishlistPage;