import React from 'react'
import {useNavigate} from 'react-router-dom';
import ProductCard2 from '../../Cards/ProductCard2/ProductCard2';
import SectionHeading2 from '../../SectionHeadings/SectionHeading2/SectionHeading2';
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const PopularProduct7 = ({data, flash}) => {
    const navigate = useNavigate()

    const goAllPage = () => {
        navigate("/products")
    };
    const pageHeading = {
        heading: "Popular Picks",
        paragraph: "Products That Have Captured Hearts",
        action: goAllPage,
        show: data?.length > 7
    }
    return (
        <section>
            <SectionWrapper>
                <SectionHeading2 data={pageHeading}/>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-5">
                    {
                        data?.slice(0, 12)?.reverse()?.map((product, i) => <div key={i}>
                            <ProductCard2 product={product} flash={product.is_on_sale === 1 ? flash : null}/>
                        </div>)
                    }
                </div>
            </SectionWrapper>
        </section>
    )
}

export default PopularProduct7