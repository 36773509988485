import React, { useState } from "react";
import useStore from "../../../Hooks/useStore";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useNavigate } from "react-router-dom";
import { Pagination, Navigation } from "swiper";
import { HiBars3 } from "react-icons/hi2";
import "swiper/css/pagination";
import "./Slider2.css";
import "swiper/css/navigation";
import "swiper/css/bundle";
import { baseUrl } from "../../../Hooks/Helper";
import { RxArrowRight } from "react-icons/rx";
const Slider2 = ({ banners, categories }) => {
  const { showDummyBanner, setSubCategoryId, setCategoryId, showDummyImage } =
    useStore().data;

  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const getProductBySubCat = async (catId, subCatId) => {
    setCategoryId(catId);
    setSubCategoryId(subCatId);
    navigate("/products");
  };

  return (
    <div className="py-2">
      <div className="container">
        {
          <div>
            <div className="grid grid-cols-12 gap-x-2 ">
              <div className="col-span-12 lg:col-span-3 relative">
                <div className="mb-2 ">
                  <div className="p-2 shadow rounded-xl bg-white dark:bg-gray-800 flex items-center justify-between relative px-4">
                    <HiBars3
                      size={22}
                      className="cursor-pointer dark:text-white"
                      onClick={() => setShow(!show)}
                    />
                    <span className="dark:text-white">All Categories</span>
                    <div className="rounded-full h-6 w-6 bg-gray-200 text-xs text-slate-700 flex items-center justify-center font-semibold">
                      {categories?.length}
                    </div>
                  </div>
                </div>
                {show && categories?.length > 0 && (
                  <div className="border rounded-xl bg-white dark:bg-slate-800 dark:border-slate-800 p-2">
                    {categories?.slice(0, 8).map((cat, i) => (
                      <div key={i}>
                        {cat?.sub_categories?.length > 0 ? (
                          <button className="py-2 block w-full  bg-transparent nav-hover">
                            <div className="flex items-center justify-between category-heading-container cursor-pointer relative">
                              <div className="category-heading flex items-center">
                                <div className="slider-category-image overflow-hidden">
                                  <img
                                    onError={({ currentTarget }) =>
                                      showDummyImage({ currentTarget })
                                    }
                                    className="w-full h-full"
                                    src={
                                      `${baseUrl.img}/${cat?.image}` ||
                                      "https://placehold.co/30x30"
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="">
                                  <p className="ml-2 text-base dark:text-white">
                                    {cat?.name}
                                  </p>
                                  <p className="h-5 w-5 rounded-full  shadow text-slate-700 dark:bg-slate-700 absolute top-0 dark:text-sky-500 flex items-center justify-center right-0 text-xs bg-gray-200">
                                    {cat?.sub_categories?.length}
                                  </p>
                                </div>
                                {cat?.sub_categories?.length > 0 && (
                                  <div className="slider3-items shadow">
                                    <div className="p-1 bg-white w-48 rounded-lg">
                                      {cat?.sub_categories?.map(
                                        (subCatItem, i) => (
                                          <div className="nav-hover" key={i}>
                                            <span
                                              className="cursor-pointer block p-2 m-0 whitespace-nowrap"
                                              onClick={() =>
                                                getProductBySubCat(
                                                  cat?.id,
                                                  subCatItem?.id
                                                )
                                              }
                                            >
                                              {subCatItem?.name}
                                            </span>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setCategoryId(cat.id);
                              navigate("/products");
                            }}
                            className="py-2 block w-full border-0 bg-transparent nav-hover"
                          >
                            <div className="flex items-center justify-between category-heading-container cursor-pointer relative">
                              <div className="category-heading flex items-center">
                                <div className="slider-category-image overflow-hidden">
                                  <img
                                    onError={({ currentTarget }) =>
                                      showDummyImage({ currentTarget })
                                    }
                                    className="w-full h-full"
                                    src={`${baseUrl.img}/${cat?.image}`}
                                    alt=""
                                  />
                                </div>
                                <span className="ml-2 dark:text-white">
                                  {cat?.name}
                                </span>
                              </div>
                            </div>
                          </button>
                        )}
                      </div>
                    ))}
                    <div className="text-center p-1">
                      {categories?.length > 8 && (
                        <Link
                          to={"/products"}
                          className="text-rose-500 w-full flex items-center justify-between rounded py-1 px-3 text-base"
                        >
                          View all categories
                          <RxArrowRight />
                        </Link>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="col-span-12 lg:col-span-9 mt-3 lg:mt-0 ">
                <div className="p-0 rounded-xl overflow-hidden">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    navigation={true}
                    pagination={{
                      clickable: true,
                    }}
                    breakpoints={{
                      "@0.00": {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      "@0.75": {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      "@1.00": {
                        slidesPerView: 1,
                        spaceBetween: 40,
                      },
                      "@1.50": {
                        slidesPerView: 1,
                        spaceBetween: 50,
                      },
                    }}
                    modules={[Pagination, Navigation]}
                    className="mySwiper banner-slider2 relative"
                  >
                   {
                    banners?.length>0 ? <div>
                       {
                      banners?.map((banner, i) => (
                        <SwiperSlide key={i}>
                          <div className="absolute w-1/2 h-full flex items-center p-4">
                            <div>
                              {
                                banner?.title &&   <h1 className="text-base lg:text-3xl font-bold text-rose-400">
                                {banner?.title}
                              </h1>
                              }
                             
                              {
                                banner?.subtitle &&<p className="my-1 leading-5 lg:my-4 text-xl lg:text-5xl font-black text-slate-700">
                                {banner?.subtitle}
                              </p>
                              }
                             {
                              banner?.button_text &&
                               <button
                               className="bg-rose-400 py-1 lg:py-2 text-xs lg:text-base px-1 lg:px-4 rounded text-white"
                               onClick={() => window.open(banner?.button_url)}
                             >
                               {banner?.button_text}
                             </button>
                             }
                            </div>
                          </div>
                          <img
                            onError={({ currentTarget }) =>
                              showDummyBanner({ currentTarget })
                            }
                            src={
                              `${baseUrl.img}${banner.image}` 
                             
                            }
                            alt=""
                            className="w-full slider3-img overflow-hidden"
                          />
                        </SwiperSlide>
                      ))}
                    </div>:<div>
                {Array.from({ length: 5 }).map((_, i) => {
                  return (
                    <SwiperSlide key={i}>
                 
                        <img
                          src= "https://placehold.co/1006x465"
                          alt=""
                          className="w-full slider3-img overflow-hidden"
                        />
                   
                    </SwiperSlide>
                  );
                })}
              </div>
                   }
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default Slider2;
