import React from 'react';
import useStore from "../../Hooks/useStore";
import {saveInCookie} from "../../Hooks/Helper";
import Rodal from "rodal";

const VisibleBranch = ({branchModalData}) => {
    const  {visibleBranch, setVisibleBranch}= branchModalData;
    const {
        setSelectedBranch,
        selectedBranch,
        branchList
    } = useStore().data;

    const innerWidth = window?.innerWidth;
    return (
        <div>
            <Rodal
                visible={visibleBranch}
                onClose={() => {
                    setVisibleBranch(!visibleBranch);
                }}
                animation={"fade"}
                customStyles={{
                    height: "auto",
                    width: innerWidth > 1024 ? "35%" : "90%",
                    bottom: "auto",
                    top: "50%",
                    transform: "translateY(-50%)",
                }}
            >
                <div>
                    <h1 className="mb-3 text-xl flex items-center border-b pb-2">
                        Choose Your Nearest Branch
                    </h1>

                    <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
                        {
                            branchList?.map((branch, i) => {
                                return (
                                    <div key={i} onClick={() => {
                                        setSelectedBranch(branch)
                                        saveInCookie("branch", JSON.stringify({label:branch.name, value: branch.id}))
                                        setVisibleBranch(false)
                                    }}
                                         className={`border cursor-pointer rounded p-2 hover:bg-black hover:text-white  duration-300 ${selectedBranch?.id === branch.id ? "bg-black text-white" : ""}`}>
                                        <h5>{branch?.name}</h5>
                                        <p className="text-sm pt-1">{branch?.address}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Rodal>
        </div>
    );
};

export default VisibleBranch;