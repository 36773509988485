import React from "react";
import { RxCross2 } from "react-icons/rx";
import { SlArrowDown } from "react-icons/sl";

const AccordionItem = ({ question, answer,isOpen, toggleAccordion  }) => {
  return (
   <div className="">
     
     <div className=" p-1 mb-1 border-gray-300 rounded-md">
      <div
        className="bg-gray-100 cursor-pointer px-4 py-2 flex justify-between items-center dark:bg-gray-800"
        onClick={toggleAccordion}
      >
        <span className="dark:text-white text-base text-bold">{question}</span>
        <div className="flex items-center justify-center">
          {isOpen ? <RxCross2 className="text-bold text-gray-500 "size={20}/> : <SlArrowDown size={15} className="dark:text-white"/>}
        </div>
      </div>
      {isOpen && (
        <div className=" bg-white border-b border-b-gray-300 px-4 py-2 pb-4 pt-2  dark:bg-gray-700 ">
          
          <p className="text-sm text-gray-700 dark:text-white">{answer}</p>
        </div>
      )}
    </div>
   </div>
  );
};

export default AccordionItem;