import React, {useEffect} from "react";
import CartContainer from "../../Components/PageContainers/CartContainer/CartContainer";
import {useDispatch} from "react-redux";
import {fetchHomeData} from "../../Redux/Slices/rootSlice/rootSlice";
import Subscription2 from "../../Components/Subscriptions/Subscription2/Subscription2";


const Cart = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchHomeData());
    }, [dispatch]);
    
    return <>
        <CartContainer/>
        <Subscription2/>
    </>;
};

export default Cart;
