import React from 'react';
import { Link } from 'react-router-dom';
import { TfiAngleRight } from "react-icons/tfi";
import './BreadCumb.css'
const BreadCumb = (props) => {
    const data = props?.data;
    return (
        <div>
            <div>
                <ul className='p-0 m-0 flex items-center'>
                    {
                        data?.map((item,i)=><li key={i} className='text-xs flex items-center dark:text-white'><Link to={item.route} className='me-2'>{item.page}</Link> {i === data.length-1 ? '' : <TfiAngleRight className='primary-text-color mr-1' size={7}/>}</li>)
                    }
                </ul>
            </div>
        </div>
    );
};

export default BreadCumb;