import React from "react";
import ProductContainer5 from "../../ProductContainers/ProductContainer5/ProductContainer5";
import {baseUrl} from "../../../Hooks/Helper";
import {useNavigate} from "react-router-dom";
import CoutDown from "../../CountDowns/CoutDown/CoutDown";
import Button from "../../Button/Button";


const FlashSale9 = ({data, site_banners, flash}) => {
    const navigate = useNavigate();
    const endDate = new Date(flash?.end_date);
    return (
        <section>
            {data?.length > 0 && (
                <div className="py-8 lg:py-16">
                    <div className="w-full h-fit bg-gray-100 dark:bg-gray-900 relative mb-4 p-4 lg:p-0">
                        <div className="xl:h-[500px] xl:w-full hidden lg:block">
                            <img
                                src={
                                    `${site_banners?.flash_sale_image ? `${baseUrl.img}/${site_banners?.flash_sale_image}` :
                                        "https://placehold.co/1905x500"}`}
                                alt=""
                                className="w-full h-full object-contain hidden lg:block"
                            />
                        </div>

                        <div
                            className="relative lg:absolute w-full h-full xl:top-0 xl:left-0 lg:top-[-40px] lg:left-0 flex items-center justify-center">
                            <div className="text-center">
                                <h2 className="text-2xl lg:text-3xl font-black text-gray-800 dark:text-gray-300">
                                    {flash?.title}
                                </h2>
                                <h4 className="text-xl lg:text-2xl font-bold text-gray-800 dark:text-gray-300">
                                    {flash?.short_description}
                                </h4>
                                <div className="w-64 mx-auto my-2 lg:my-4">
                                    <CoutDown endDate={endDate} data={data}/>
                                </div>
                                <div className="w-full lg:w-5/12 mx-auto">
                                    <p className="text-sm dark:text-gray-400">
                                        Score huge discounts on our shop. Limited stock available, so start shopping
                                        now!
                                    </p>
                                </div>
                                {/* <button
                  className="bg-gray-200 py-1 px-3 mt-2 border bg-transparent text-gray-200 hover:bg-orange-500"
                  onClick={() => navigate("/products")}
                >
                  SHOP NOW
                </button> */}
                                <div className="flex justify-center mt-3">
                                    <Button
                                        classes={""}
                                        size={"md"}
                                        title={"SHOP NOW"}
                                        func={() => navigate("/products")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <ProductContainer5 data={data} flash={flash ? flash : null}/>
                </div>
            )}
        </section>
    );
};

export default FlashSale9;
