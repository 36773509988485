import React, { useState } from "react";
import {AdvancedMarker, APIProvider, ControlPosition, Map, MapControl} from "@vis.gl/react-google-maps";
import {baseUrl} from "../../Hooks/Helper";
import {AutoCompleteMap} from "./AutocompleteMap";
import MapHandler from "./MapHandler";


const GoogleMap = ({position, setPosition}) => {

    const defaultCenter = {lat: 23.810331, lng: 90.412521}
    const [selectedPlace, setSelectedPlace] = useState(null);
    // const [position, setPosition] = useState(null);

    const handleMapClick = (event) => {
        const clickedPosition = {
            lat: event.detail?.latLng.lat,
            lng: event.detail?.latLng.lng
        };

        setPosition(clickedPosition); // Set the new position
    };

    return (
        <div>
            <APIProvider apiKey={baseUrl.mapKeys}>
                <Map
                    mapId={baseUrl.mapId}
                    style={{width: '100%', height: '400px'}}
                    defaultCenter={defaultCenter}
                    defaultZoom={13}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                    onClick={handleMapClick}
                />
                <MapControl position={ControlPosition.TOP}>
                    <div className="autocomplete-control">
                        <AutoCompleteMap onPlaceSelect={setSelectedPlace} setPosition={setPosition}/>
                    </div>
                </MapControl>
                    <AdvancedMarker position={position}/>
                <MapHandler place={selectedPlace} />
            </APIProvider>
        </div>
    );
};

export default GoogleMap;