import React from "react";
import { baseUrl } from "../../../Hooks/Helper";

const Slider8 = ({ banners }) => {
  return (
    <div className="pb-8 lg:pb-16">
      <section className="container mt-3">
        <div className="grid grid-cols-3  lg:grid-cols-4  gap-2 ">
          <div className="lg:col-span-2 col-span-3">
            <div className="">
              {banners?.length > 0 && banners[0]?.image ? (
                <img
                  src={`${baseUrl.img}/${banners[0]?.image}`}
                  alt=""
                  className="w-full xl:h-[443px]   object-cover"
                />
              ) : (
                <img
                  src={"https://placehold.co/668x443"}
                  alt=""
                  className="w-full xl:h-[443px]   object-cover"
                />
              )}
            </div>
          </div>
          <div className="col-span-1">
            <div className="">
              {banners?.length > 0 && banners[1]?.image ? (
                <img
                  src={`${baseUrl.img}/${banners[1]?.image}`}
                  alt=""
                  className="w-[330px] h-[168px] lg:w-[330px] lg:h-[443px] object-cover"
                />
              ) : (
                <img src={"https://placehold.co/330x443"} alt="" />
              )}
            </div>
          </div>

          <div className="col-span-1">
            <div className="font-bold">
              {banners?.length > 0 && banners[2]?.image ? (
                <img
                  src={`${baseUrl.img}/${banners[2]?.image}`}
                  alt=""
                  className="w-[330px] h-[168px] lg:w-[330px] lg:h-[443px] object-cover"
                />
              ) : (
                <img src={"https://placehold.co/330x443"} alt="" />
              )}
            </div>
          </div>

          <div className="col-span-1">
            <div className="font-bold">
              {banners?.length > 0 && banners[3]?.image ? (
                <img
                  src={`${baseUrl.img}/${banners[3]?.image}`}
                  alt=""
                  className="w-[330px] h-[168px] lg:w-[330px] lg:h-[443px] object-cover"
                />
              ) : (
                <img src={"https://placehold.co/330x443"} alt="" />
              )}
            </div>
          </div>

          <div className="col-span-1">
            <div className="font-bold">
              {banners?.length > 0 && banners[4]?.image ? (
                <img
                  src={`${baseUrl.img}/${banners[4]?.image}`}
                  alt=""
                  className="w-[330px] h-[168px] lg:w-[330px] lg:h-[443px] object-cover"
                />
              ) : (
                <img src={"https://placehold.co/330x443"} alt="" />
              )}
            </div>
          </div>

          <div className="lg:col-span-2 col-span-2">
            <div className="">
              {banners?.length > 0 && banners[5]?.image ? (
                <img
                  src={`${baseUrl.img}/${banners[5]?.image}`}
                  alt=""
                  className="w-full h-[168px] lg:h-[443px] object-cover"
                />
              ) : (
                <img src={"https://placehold.co/668x443"} alt="" />
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Slider8;
