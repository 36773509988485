import React from 'react';
import { baseUrl } from '../../../Hooks/Helper';
import './FeatureBanner.css'
const FeatureBanner = ({site_banners}) => {
    return (
        <section className='my-8 lg:my-16'>
            <div className="container" >
                <img src={`${baseUrl.img}/${site_banners?.featured_banner_image}`} alt="" className="w-full" />
            </div>
          
        </section>
    );
};

export default FeatureBanner;