import {RouterProvider} from "react-router-dom";
import {router} from "./Router/Router";
import {useEffect, useState} from "react";
import Customization from "./Components/Customization/Cutomization";
import useStore from "./Hooks/useStore";
import "./App.css";
import {baseUrl, getFromCookie, saveInCookie} from "./Hooks/Helper";
import Loader2 from "./Components/Loaders/Loader2/Loader2";
import {Helmet} from 'react-helmet-async';
import Rodal from "rodal";
// import * as settings from "framer-motion/m";


const App = () => {
    const {setting, setSelectedBranch} = useStore().data;
    const pixelCode = setting?.pixel_id;
    window._DEFAULT_DATA = 'Data parsed in string';
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [welcomeData, setWelcomeData] = useState({});

    useEffect(() => {
        // Set a global variable with the context value
        window.REACT_APP_CONTEXT_VALUE = pixelCode;
        localStorage.setItem("code", pixelCode)
        saveInCookie("code", pixelCode)
    }, [pixelCode]);

    const [webSiteInfos, setWebsiteInfos] = useState({
        title: '',
        description: '',
        logo: ''
    });

    // useEffect(() => {
    //     window.onfocus = function () {
    //         document.title = setting?.name;
    //     };
    //     window.onblur = function () {
    //         document.title = "Come back soon";
    //     };
    // }, [setting?.name]);


    useEffect(() => {
        setWebsiteInfos({
            title: setting?.name,
            description: setting?.about,
            logo: setting?.favicon
        })
        // window.onblur = function () {
        //     document.title = "Come back soon";
        // };
    }, [setting])


    ///APP.JS
    useEffect(() => {
        const domainName = window.location.host;
        // const domainName = 'tailortrends.seloboard.store'
        // const domainName = 'zerovapebd.com'
        const getApiUrl = async () => {
            try {
                const domainCookie = document.cookie.split('; ').find(row => row.startsWith('domain_name='));
                const apiKeyCookie = document.cookie.split('; ').find(row => row.startsWith('api_key='));

                if (!domainCookie || !apiKeyCookie) { // Only make the API call if the cookies are not already set
                    const res = await fetch(`https://selopia.store/api/super-admin/check-domain/${domainName}`, {
                        method: "GET",
                    });
                    if (res.ok && res.status !== 204) {
                        const resData = await res.json();
                        // document.cookie = `domain=${resData.domain}`;
                        document.cookie = `domain_name=api.${domainName}`
                        document.cookie = `api_key=${resData?.api_key}`;
                        window.location.reload();
                    }
                }
            } catch (e) {
                console.error("error", e);
            } finally {
                setLoading(false);
            }
        };
        getApiUrl().then();
    }, []);



    useEffect(() => {
        if (!baseUrl.token) {
            return;
        }
        const getBranch = async () => {

            if(Number(setting?.welcome_pop_up) !== 1 ){
                return;
            }

            try {
                const res = await fetch(`${baseUrl.url}/get-welcome-popup`, {
                    method: "GET",
                    headers: {
                        Authorization: baseUrl.token,
                    },
                });
                const resData = await res.json();
                if (res.ok) {
                    setWelcomeData(resData?.data);
                    setVisible(true);
                }
            } catch (e) {
                console.error("error", e)
            }
        };
        getBranch().then();
    }, [setting]);


    const branch = getFromCookie("branch");
    useEffect(() => {
        if (branch) {
            const branchName = JSON.parse(branch)
            setSelectedBranch(branchName);
        }
    }, [branch, setSelectedBranch]);

    if (loading) {
        return <Loader2/>
    }

    return (
        <>
            <Helmet>
                <title>{webSiteInfos?.title}</title>
                <meta name="description" content={webSiteInfos?.description}/>
                <link rel="icon" type="image/png" href={webSiteInfos.logo} sizes="16x16"/>
            </Helmet>

            {/*<Helmet>*/}
            {/*    <title>My Page Title</title>*/}
            {/*    <meta name="description" content="This is a description of the page." />*/}
            {/*    <meta name="keywords" content="React, Helmet, SEO" />*/}
            {/*</Helmet>*/}

            {
                <div className="App bg-slate-50 dark:bg-gray-950">
                    <Customization/>
                    <RouterProvider router={router}/>
                </div>
            }


            <Rodal
                visible={visible && (getFromCookie("welcomePopup")!== "1")}
                onClose={() => {
                    setVisible(!visible)
                    saveInCookie("welcomePopup", 1);
                }}
                closeMaskOnClick={false}
                customStyles={{
                    minWidth: "400px",
                    height: "auto",
                    width: "25%",
                    bottom: "auto",
                    top: "50%",
                    transform: "translateY(-50%)",
                }}
            >
                <h3 className="text-lg border-b py-3">{welcomeData?.title}</h3>
                <div>
                    {/*  body  */}

                    <div className="">
                        <div className="flex justify-center items-center">
                            <img src={`${baseUrl.img}${welcomeData?.image}`} alt="" className="w-full "/>
                        </div>

                        <div className="pt-3 text-center">
                            <div className="flex justify-center items-center h-full w-full">
                               <p className="text-md font-medium">{welcomeData?.details}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    Number(welcomeData?.button) === 1 && <div className="mt-4">
                        <button
                            onClick={() => {
                                setVisible(!visible);
                                saveInCookie("welcomePopup", 1);
                            }}
                            className="w-full bg-indigo-500 text-white p-2 rounded"
                        >
                            {welcomeData?.button_text}
                        </button>
                    </div>
                }


            </Rodal>
        </>
    );
};

export default App;
