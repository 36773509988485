import React, {useEffect, useState} from "react";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "rodal/lib/rodal.css";
import {baseUrl, errorNotify, successNotify} from "../../../Hooks/Helper";
import useStore from "../../../Hooks/useStore";
import "leaflet/dist/leaflet.css";
import Button from "../../Button/Button";
import AddressSave from "../../AddressSave/AddressSave";

const ShippingDetails = () => {
    const {
        customerToken,
        update,
        setUpdate,
        setting
    } = useStore().data;
    const [showAddModal, setShowAddModal] = useState(false);
    const [addresses, setAddresses] = useState([]);
    // selected option value

    // get user address-list
    useEffect(() => {
        if (!customerToken) {
            return;
        }
        const getUserDetails = async () => {
            try {
                const res = await fetch(`${baseUrl.url}/user/address-list`, {
                    headers: {
                        Authorization: `bearer ${customerToken}`,
                    },
                });

                if (res.ok && res.status !== 204) {
                    const userData = await res.json();
                    setAddresses(userData.data);
                } else {
                    setAddresses([]);
                }
            } catch (e) {
                console.error("error", e);
            }
        };
        getUserDetails().then();
    }, [customerToken, update, setUpdate]);


    // delete user address-list
    const removeAddress = async (id) => {
        const res = await fetch(`${baseUrl.url}/user/address-delete/${id}`, {
            method: "DELETE",
            headers: {
                Authorization: `bearer ${customerToken}`,
            },
        });
        const resData = await res.json();
        if (res.ok) {
            successNotify("successfully remove your address");
            setUpdate(!update);
        } else {
            resData?.errors.map((err) => errorNotify(err));
        }
    };


    const visibleModal = {
        showAddModal, setShowAddModal
    }

    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <AddressSave visibleModal={visibleModal}  />

            <div className="flex align-items-start justify-between">
                <div>
                    <h2 className="text-lg text-slate-500 dark:text-white tracking-wider">
                        Shipping address
                    </h2>
                </div>
                <div>
                    <Button size={"md"} func={() => setShowAddModal(true)} title={"Add Address"}/>
                </div>
            </div>

            <div className="pb-4 grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-2 mt-8">
                {addresses
                    ?.slice(0, addresses?.length)
                    .reverse()
                    .map((add, i) => (
                        <div
                            key={i}
                            className="bg-slate-100 dark:bg-slate-900 p-4 relative group"
                        >
                            <div className="flex items-center justify-between">
                                <h1 className="text-2xl text-gray-400 ">
                                    {i + 1}
                                </h1>
                                <button onClick={() => removeAddress(add?.id)}
                                        className="text-gray-500 group-hover:text-red-500 text-sm">Remove
                                </button>
                            </div>
                            <div className={`rounded cursor-pointer `}>

                                {
                                    +setting.default_delivery_system !== 3 &&
                                    <p className="dark:text-white mt-4 text-sm">
                                        {add?.upazila?.district?.division?.country?.name},
                                        {add?.upazila?.district?.division?.name},
                                        {add?.upazila?.district?.name},
                                        {add?.upazila?.name}
                                    </p>

                                }

                                <p className="dark:text-white text-sm">{add?.address}</p>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default ShippingDetails;
