import React from "react";
import { baseUrl } from "../../../Hooks/Helper";
// import SectionHeading2 from "../../SectionHeading2/SectionHeading2";
import "./FeatureBanner2.css";
const FeatureBanner2 = ({ site_banners }) => {
  return (
    <section className="py-8 lg:py-16">
        <img
          src={`${baseUrl.img}/${site_banners?.featured_banner_image}`}
          alt=""
          className="w-full h-full"
        />
    </section>
  );
};

export default FeatureBanner2;
