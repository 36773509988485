import React from 'react';
import ProductCard from '../../Components/Cards/ProductCard/ProductCard';
import useStore from '../../Hooks/useStore'
const CategoryPage = () => {
    const {categoryFilteredProducts} = useStore().data


    return (
        <section className='allProduct'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className='p-0 p-lg-3'>
                            <div className='product-container2 py-3' style={{gridTemplateColumns: `repeat(${5},1fr)`,gap:'.4rem'}}>
                                {categoryFilteredProducts?.map((product,i)=><div key={i}><ProductCard product={product}/></div>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CategoryPage;