import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl, errorNotify, successNotify } from "../../Hooks/Helper";
import { RxCross1 } from "react-icons/rx";
import { MdAdd } from "react-icons/md";

import useStore from "../../Hooks/useStore";
import { BiLoader } from "react-icons/bi";
const Register = () => {
  const navigate = useNavigate();
  const { setting, darkMode } = useStore().data;
  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [preview, setPreview] = useState(null);
  const newpassword = watch("password");
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (data) => {

    let formData = new FormData();
    for (const iterator in data) {
      if (iterator !== "avatar") {
        const name = data[iterator];
        formData.append(`${iterator}`, name);
      }
    }
    if (preview) {
      formData.append("avatar", preview);
    }
    
    try{
      setIsLoading(true);
      const res = await fetch(`${baseUrl.url}/user/register`, {
        method: "POST",
        body: formData,
      });
      const userData = await res.json();
      if (res.ok) {
        successNotify('Your account is created successfully')
        reset();
        // setIsLoading(false);
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      } else {
        userData?.errors?.map((error) => errorNotify(error));
      }
    }catch (e) {
      
    }finally {
      setIsLoading(false)
    }

  };

  const validateConfirmPassword = (value) => {
    if (value === newpassword) {
      return true;
    } else {
      return "Passwords is not matching...";
    }
  };
  return (
    <div className="login-page">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* absolute top-0 start-0 */}
      <div className="h-screen flex items-center justify-center ">
        <div className="p-3 md:p-6 shadow-lg bg-white dark:bg-slate-800 w-[450px] overflow-hidden">
          <div className="text-center">
            <div className={`block`}>
              <Link to={"/"} className=" w-full flex justify-center">
                {setting?.dark_logo || setting?.logo ? (
                  <img
                    className={"w-56"}
                    src={
                      darkMode
                        ? `${baseUrl.img}${setting?.dark_logo}`
                        : `${baseUrl.img}${setting?.logo}`
                    }
                    alt={"website logo"}
                  />
                ) : (
                  ""
                )}
              </Link>
            </div>
            <h5 className="m-0 text-xl dark:text-white">Register</h5>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="text-left mt-2">
              <div className="">
                {!preview ? (
                  <div className="flex items-center justify-between border-b pb-2">
                    <label className="text-color font4 dark:text-white" htmlFor="">
                      Add profile photo
                    </label>
                    <div
                      style={{ height: "45px", width: "45px" }}
                      className="relative"
                    >
                      <input
                        type="file"
                        {...register("avatar")}
                        onChange={(e) => setPreview(e.target.files[0])}
                        style={{ opacity: "0", zIndex: "5" }}
                        className="relative"
                      />
                      <div
                        style={{ zIndex: "1" }}
                        className="absolute top-0 left-0 flex items-center justify-center"
                      >
                        <MdAdd size={25} className="dark:text-white" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="flex justify-end">
                      <RxCross1 onClick={() => setPreview(null)} className="dark:text-white" />
                    </div>
                    <div
                      style={{ width: "75px", height: "75px" }}
                      className="mx-auto rounded-full overflow-hidden"
                    >
                      <img
                        src={URL.createObjectURL(preview)}
                        className="w-full h-full"
                        style={{ objectFit: "cover" }}
                        alt=""
                      />
                    </div>
                  </div>
                )}

                <p className="text-red-500 m-0">{errors.avatar?.message}</p>
              </div>
              <div className="pt-2">
                <label className="text-color font4 mb-1 block dark:text-white" htmlFor="">
                  User Name
                </label>
                <input
                  placeholder="John doe"
                  type="text"
                  {...register("name", { required: "Name is required" })}
                  className="w-full rounded-lg p-2 text-sm bg-slate-100 focus:outline-none"
                />
                {errors.name && (
                  <p className="text-red-500 m-0 text-xs">
                    {errors.name.message}
                  </p>
                )}
              </div>

              <div className="pt-2">
                <label className="text-color font4 mb-1 block dark:text-white" htmlFor="">
                  User Email
                </label>
                <input
                  type="email"
                  placeholder="example@gmail.com"
                  {...register("username", {
                    required: "Email is required",
                  })}
                  className="w-full rounded-lg p-2 text-sm bg-slate-100 focus:outline-none"
                />
                {errors.username && (
                  <p className="text-red-500 m-0 text-xs">
                    {errors.username.message}
                  </p>
                )}
              </div>
              <div className="flex gap-3 pt-2">
                <div className="w-8/12">
                  <label className="text-color font4 mb-1 block dark:text-white" htmlFor="">
                    Phone
                  </label>
                  <input
                    type="text"
                    placeholder="+88019XX XXX XXX"
                    {...register("phone", {
                      required: "Phone number is required",
                    })}
                    className="w-full rounded-lg p-2 text-sm bg-slate-100 focus:outline-none"
                  />
                  {errors.phone && (
                    <p className="text-red-500 m-0 text-xs">
                      {errors.phone.message}
                    </p>
                  )}
                </div>
                <div className="w-4/12">
                  <label className="text-color font4 mb-1 block dark:text-white" htmlFor="">
                    Gender
                  </label>
                  <select
                    className="w-full rounded-lg p-2 text-sm bg-slate-100 focus:outline-none"
                    name=""
                    {...register("gender", { required: "Gender is required" })}
                    id=""
                  >
                    <option value="">Select gender</option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                    <option value="3">Others</option>
                  </select>

                  {errors.gender && (
                    <p className="text-red-500 m-0 text-xs">
                      {errors.gender.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="pt-2">
                <label className="text-color font4 mb-1 block dark:text-white" htmlFor="">
                  Password
                </label>
                <input
                  {...register("password", {
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long",
                    },
                  })}
                  placeholder="Password"
                  type="password"
                  className="w-full rounded-lg p-2 text-sm bg-slate-100 focus:outline-none"
                />
                {errors.password && (
                  <p className="text-red-500 m-0 text-xs">
                    {errors.password.message}
                  </p>
                )}
              </div>
              <div className="pt-2">
                <label className="text-color mb-1 block dark:text-white" htmlFor="">
                  Confirm Password
                </label>
                <input
                  {...register("password_confirmation", {
                    required: "Confirm password is required",
                    validate: validateConfirmPassword,
                  })}
                  type="password"
                  placeholder="Confirm password"
                  className="w-full rounded-lg p-2 text-sm bg-slate-100 focus:outline-none"
                />
                {errors.password_confirmation && (
                  <p className="text-red-500 m-0 text-xs">
                    {errors.password_confirmation.message}
                  </p>
                )}
              </div>
              <p className="text-sm mt-4 text-slate-500 dark:text-white">
                Your personal data will be used to support your experience
                throughout this website, to manage access to your account, and
                for other purposes described in our{" "}
                <Link className="text-blue-800 " to={"/terms-condition"}>
                  Privacy policy
                </Link>{" "}
                .
              </p>
            </div>

            <button
              disabled={isLoading && true}
              className={`flex justify-center items-center gap-2 mt-4 font-medium w-full p-2 rounded-lg transition ease-in duration-200 border text-white text-sm  cursor-pointer ${
                isLoading ? "bg-red-300" : "bg-red-400"
              }`}
              type="submit"
            >
              {isLoading && <BiLoader size={20} />}
              Create Account
            </button>
          </form>
          <p className="text-center text-sm mt-2 dark:text-white">
            Already registered? login{" "}
            <Link to="/login" className="text-blue-600 underline">
              here
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
