import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { baseUrl } from "../../../Hooks/Helper";


const initialState = {
    allProducts: [],
    paginate: [],
    productLength: 0,
    loading: false,
    err: null,
    updates: false,
}

export const fetchAllProducts = createAsyncThunk("fetchAllProducts/product", async() =>{
    const res = await fetch(`${baseUrl.url}/product-filter?per_page=20`, {
        method: 'GET',
        headers: {
            'Authorization':  baseUrl?.token 
        },
    })
    const resData = await res.json();
    return resData.data
})

const allProductsSlice = createSlice({
    name: "All Products",
    initialState,
    reducers: {
        setPaginate: (state, action)=>{
            state.paginate = action?.payload;
        },
        setLoaderAll: (state, action)=>{
            state.loading = action?.payload;
        }
    },
    extraReducers:(builder =>{
        builder.addCase(fetchAllProducts.pending, state =>{
            state.loading = true
        }).addCase(fetchAllProducts.fulfilled, (state, action) =>{
            state.loading = false
            state.allProducts = action.payload?.data
            state.paginate = action.payload?.links
            state.productLength = action?.payload?.total
            state.err = null
        }).addCase(fetchAllProducts.rejected, (state, action) =>{
            state.loading = false
            state.allProducts = []
            state.paginate = []
            state.productLength = 0
            state.err = action.payload
        })
    })
})
export  const {setPaginate, setLoaderAll} = allProductsSlice.actions;
export default  allProductsSlice.reducer;