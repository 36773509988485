import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllcustomerOrder } from "../../../Redux/Slices/Customer/customerOrderSlice";
import useStore from "../../../Hooks/useStore";
import { baseUrl, errorNotify, successNotify } from "../../../Hooks/Helper";
import { ToastContainer } from "react-toastify";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
const OrderTab = () => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state?.customerOrder?.customerOrder);
  const navigate = useNavigate();
  const [orderCancelModal, setOrderCancelModal] = useState(false);
  const [orderCancelID,setOrderCancelID]=useState('')
  const { customerToken, update, setUpdate } = useStore().data;
  const [cancelReason, setCancelReason] = useState('');
  const handleReasonChange = (event) => {
    setCancelReason(event.target.value);
  };

  useEffect(() => {
    dispatch(fetchAllcustomerOrder(customerToken));
  }, [dispatch, customerToken,update]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const orderCancel = async () => {
   
    if (customerToken && customerToken?.length > 20) {
     
      const res = await fetch(`${baseUrl.url}/user/cancel-order/${orderCancelID}?reason=${cancelReason}`, {
        method: "GET",
        headers: {
         
          Authorization: `bearer ${customerToken}`,
        },
      
      });
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        successNotify("Successfully Cancel your order");
        setOrderCancelModal(!orderCancelModal)
      } else {
        resData?.errors.map((err) => errorNotify(err));
      }
    }
  };
  
  return (
    <div>
       <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
        <Rodal
        visible={orderCancelModal}
        onClose={() => {
          setOrderCancelModal(!orderCancelModal);
        }}
        animation={"zoom"}
        customStyles={{
          minWidth: "350px",
          height: "auto",
          width: "20%",
          bottom: "auto",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <div className="p-1">
          

          <h4 className="font-bold border-b">Please select a valid reason</h4>

          <div className="space-y-1 mt-1">
        <label className="flex items-center">
          <input
            type="radio"
            value="DELIVERY_ETA_TOO_LONG"
            checked={cancelReason === 'DELIVERY_ETA_TOO_LONG'}
            onChange={handleReasonChange}
            className="form-radio"
          />
          <span className="ml-2">Delivery time is too long</span>
        </label>
        <label className="flex items-center">
          <input
            type="radio"
            value="MISTAKE_ERROR"
            checked={cancelReason === 'MISTAKE_ERROR'}
            onChange={handleReasonChange}
            className="form-radio"
          />
          <span className="ml-2">Incorrect specifications</span>
        </label>
        <label className="flex items-center">
          <input
            type="radio"
            value="REASON_UNKNOWN"
            checked={cancelReason === 'REASON_UNKNOWN'}
            onChange={handleReasonChange}
            className="form-radio"
          />
          <span className="ml-2">Other</span>
        </label>
          </div>

          <div className="flex justify-end items-center mt-3">
            <button
              className="text-sm  bg-red-500 text-white py-1 px-3 rounded"
              onClick={() => {
               setOrderCancelModal(!orderCancelModal);
              }}
            >
              Cancel
            </button>
            <button
              className="ml-2 text-sm  bg-green-500 text-white py-1 px-3 rounded"
              onClick={ orderCancel}
            >
              Confirm
            </button>
          </div>
        </div>
      </Rodal>
      <div className="account-information-section mb-4">
        <h2 className="text-lg text-slate-500 dark:text-white tracking-wider">
          Order details
        </h2>
      </div>
      {orders?.length > 0 ? (
        <div className="mt-8">
      
          <div className="overflow-scroll lg:overflow-hidden">
            <table className="w-full">
              <thead>
                <tr className="border-b">
                  <th className="text-start p-2 bg-slate-100 dark:bg-gray-900 border-b border-gray-300 dark:border-gray-800 dark:text-white">
                    Date
                  </th>
                  <th className="text-start p-2 bg-slate-100 dark:bg-gray-900 border-b border-gray-300 dark:border-gray-800 dark:text-white">
                    Order ID
                  </th>
                  <th className="text-start p-2 bg-slate-100 dark:bg-gray-900 border-b border-gray-300 dark:border-gray-800 dark:text-white">
                    Status
                  </th>
                  <th className="text-start p-2 bg-slate-100 dark:bg-gray-900 border-b border-gray-300 dark:border-gray-800 dark:text-white">
                   Details
                  </th>
                  <th className="text-start p-2 bg-slate-100 dark:bg-gray-900 border-b border-gray-300 dark:border-gray-800 dark:text-white">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders?.map((order, i) => {
                  const date = new Date(order?.created_at);
                  const formattedDate = date.toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  });
                  return (
                    <tr
                      key={i}
                      className="w-full cursor-pointer"
                     
                    >
                      <td className="dark:text-gray-300 text-sm">
                        {formattedDate}
                      </td>
                      <td className="dark:text-gray-300" >{order?.order_number}</td>
                      <td>
                        <span
                          className={`w-full font-medium px-1 text-xs rounded-md ${order?.order_status === "Delivered"
                          ? "text-green-600"
                          : order?.order_status === "Confirmed"
                          ? "text-blue-400"
                          : order?.order_status === "Cancelled"
                          ? "text-red-500"
                          : "#7e4700"}`}
                        >
                          {order?.order_status}
                        </span>
                      </td>
                      <td>
                      <button
                            className="px-2 py-0 mt-2 bg-red-50 text-green-500 dark:bg-red-100 dark:text-black"
                            onClick={() =>
                              navigate(`/profile/order/details/${order?.id}`)
                            }
                          >
                           
                            Order Details
                          </button>
                      </td>

                      {order?.order_status === "Confirmed" ||
                      order?.order_status === "Pending" ? (
                        <td className="">
                          <button
                            className="px-2 py-0 mt-2 bg-red-50 text-red-500 dark:bg-red-300 dark:text-black"
                            onClick={() => {
                              setOrderCancelModal(!orderCancelModal);
                              setOrderCancelID(order?.id)
                             }}
                          >
                           
                            Cancel order
                          </button>

                          

                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="p-5 my-5 text-center dark:text-white">
          <h5>No item in Orders</h5>
        </div>
      )}
    </div>
  );
};

export default OrderTab;
