import React from 'react'

const SectionHeading5 = ({data}) => {
    const {heading,paragraph} = data
    const headings = heading.split(" ")
    return (
        <section className='text-center'>
            <h2 className='poppin text-3xl inline font-semibold'><span className='bg-black text-white px-2'>{headings[0]}</span><span className='dark:text-white px-2'>{headings[1]}</span></h2>
            <p className='mt-2 poppin dark:text-white'>{paragraph}</p>
        </section>
    );
}

export default SectionHeading5