import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { Link } from "react-router-dom";
import { baseUrl } from "../../../../Hooks/Helper";
// import { baseUrl } from "../../../Hooks/Helper";
const ProductCard2 = ({product,flash}) => {
  const {
    id,
    name,
    thumbnail_image,
    display_price,
    previous_display_price,
    inventories_sum_stock_quantity,
    product_review_rating,
    slug
  } = product;
 
  const discount =
    ((previous_display_price - display_price) / previous_display_price) * 100;
    
    const flashSalemoney = (display_price * flash?.discount) / 100;
  return (
    <Link to={`/details/${id}/${slug}`} className="text-decoration-none bg-white dark:bg-gray-900 p-2 ">
      <div>
        <div className="bg-gray-200 mb-2 h-[180px] w-full">
          <img
            src={`${baseUrl.img}${thumbnail_image}`||"https://placehold.co/195x195"}
            alt=""
            className="w-auto h-full mx-auto"
          />
        </div>
        <div className="p-2">
          <div className="flex justify-between items-center">
            
            <h5 className="m-0 dark:text-sky-500">{name?.length > 15 ? name?.slice(0, 13) + "..." : name}</h5>
            {inventories_sum_stock_quantity > 0 ? (
              <p className="text-green-500 font-normal text-sm">In Stock</p>
            ) : (
              <p className="text-red-500 font-normal text-sm">Out of Stock</p>
            )}
          </div>
          <p className="mb-0 flex items-center">
              {Array.from({ length: 5 }).map((_, i) => {
                if (i < parseInt(product_review_rating[0]?.avg_review_rating)) {
                  return <AiFillStar key={i} className="text-orange-500" />;
                }
                return <AiOutlineStar key={i} className="text-gray-400" />;
              })}
            </p>

          <div className="flex justify-between">
          {
            flash?  <p className="dark:text-white font-bold">
            ৳ {display_price-flashSalemoney}
          </p> :  <p className="dark:text-white font-bold">
            ৳ {display_price}
          </p>
          }

            {
              flash?.discount>0 ? <h6 className="text-sm dark:text-white">{flash?.discount.toFixed(0)}% Off Now</h6> :discount>0 ?<h6 className="text-sm dark:text-white">{discount.toFixed(0)}% Off Now</h6> :''
            }
        
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProductCard2;
