import React from "react";
import "./AccountInfo.css";
import useStore from "../../../../Hooks/useStore";
const AccountInfo = () => {
  const { customerInfo } = useStore().data;
  return (
    <div>
      <div>
        <h2 className="text-lg text-slate-500 dark:text-white tracking-wider">
          Account informations
        </h2>
      </div>
      <div className="col-span-12 md:col-span-10 lg:col-span-6 mt-8">
        <div className="grid grid-cols-12 my-1 lg:my-2 account-info">
          <div className="col-span-5 lg:col-span-3">
            <span className="fw-bold font-medium dark:text-white">
              Email Address:
            </span>
          </div>
          <div className="col-span-7 lg:col-span-9">
            <span className="dark:text-white">
              {customerInfo?.user_data?.username}
            </span>
          </div>
        </div>
        <div className="grid grid-cols-12 my-1 lg:my-2 account-info">
          <div className="col-span-5 lg:col-span-3">
            <span className="fw-bold font-medium dark:text-white">
              Full Name:
            </span>
          </div>
          <div className="col-span-7 lg:col-span-9">
            <span className="dark:text-white">
              {customerInfo?.user_data?.name}
            </span>
          </div>
        </div>
        <div className="grid grid-cols-12 my-1 lg:my-2 account-info">
          <div className="col-span-5 lg:col-span-3">
            <span className="fw-bold font-medium dark:text-white">Phone:</span>
          </div>
          <div className="col-span-7 lg:col-span-9">
            <span className="dark:text-white">
              {customerInfo?.user_data?.phone}
            </span>
          </div>
        </div>
        <div className="grid grid-cols-12 my-1 lg:my-2 account-info">
          <div className="col-span-5 lg:col-span-3">
            <span className="fw-bold font-medium dark:text-white">Gender:</span>
          </div>
          <div className="col-span-7 lg:col-span-9">
            <span className="dark:text-white">
              {customerInfo?.user_data?.profile?.gender?.name}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;
