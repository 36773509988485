import React from 'react';
import { BsArrowRight } from "react-icons/bs";
import { sliceWord } from '../../../Hooks/Helper';
const SectionHeading = ({data}) => {
    const {heading,paragraph,action,show} = data
    const words = sliceWord(heading)
    return (
        <div className='section-heading2 flex items-center justify-between'>
            <div>
                <h5 className={`text-2xl font-bold flex items-center`}> <span className='w-8 h-8 bg-red-400 rounded-full flex items-center justify-center text-white'>{words[0]}</span> <span className='dark:text-white'>{words[1]}</span> </h5>
                {(paragraph!==null) && <p className='text-sm dark:text-white'>{paragraph}</p>}
            </div>
            {
                show && <button className='p-1 px-3  border-0 flex items-center rounded-full text-red-400 ' onClick={action}>View all<BsArrowRight/></button>
            }
        </div>
    );
};

export default SectionHeading;