import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import useStore from "../../../Hooks/useStore";
import { baseUrl } from "../../../Hooks/Helper";
import { Link } from "react-router-dom";

const ProductCard4 = ({ data, flash }) => {
  const { showDummyImage } = useStore().data;
  const {
    id,
    name,
    thumbnail_image,
    slug,
    category,
    inventories_sum_stock_quantity,
    product_review_rating,
    thumbnail_background,
    display_price,
    previous_display_price,
  } = data;

  const flashSalemoney = parseFloat(display_price * flash?.discount) / 100;
  
  return (
    <Link to={`/details/${id}/${slug}`} className="max-h-[400px]">
      <div className="h-[230px] w-full bg-gray-100 group">
        {thumbnail_image ? (
          <img
            loading="lazy"
            onError={({ currentTarget }) => showDummyImage({ currentTarget })}
            src={`${baseUrl.img}${thumbnail_image}`}
            alt=""
            className={`w-auto mx-auto rounded group-hover:scale-105  transition-all duration-500 h-full ${thumbnail_background}`}
          />
        ) : (
          <img
            src={"https://placehold.co/256x256"}
            alt=""
            className={`w-full rounded group-hover:scale-105  transition-all duration-500 h-full`}
          />
        )}
      </div>
      <div className="flex items-center justify-between border-b pb-2 my-2 px-4">
        <p className="text-sm text-gray-700 dark:text-white">
          {category?.name}
        </p>

        <p className="mb-0 flex items-center">
          {Array.from({ length: 5 }).map((_, i) => {
            if (i < parseInt(product_review_rating[0]?.avg_review_rating)) {
              return <AiFillStar key={i} className="text-orange-500" />;
            }
            return <AiOutlineStar key={i} className="text-gray-400" />;
          })}
        </p>
      </div>
      <div className="text-center p-2">
        <div className="flex justify-between">
          <h1 className="text-gray-800 dark:text-orange-500 whitespace-pre-wrap">
            {name?.length > 15 ? name?.slice(0, 20) + "..." : name}
          </h1>
          {inventories_sum_stock_quantity > 0 ? (
            <p className="text-green-500 font-normal text-xs whitespace-nowrap">
              In Stock
            </p>
          ) : (
            <p className="text-red-500 font-normal text-xs whitespace-nowrap">
              Out of Stock
            </p>
          )}
        </div>
        <div className="flex items-center  justify-between">
          {flash?.discount ? (
            <p className="mr-2 text-red-400 font-bold text-lg ">
              ৳ {display_price - flashSalemoney}
            </p>
          ) : (
            <p className="mr-2 text-red-400 font-bold text-lg ">
              ৳ {display_price}
            </p>
          )}

          {previous_display_price && (
            <p className="ml-2 text-base text-red-400 ">
              <del>৳{previous_display_price}</del>
            </p>
          )}
          {flash?.discount && (
            <p className="flex gap-1 dark:text-white">
              <del className="text-red-400 font-bold">৳{flashSalemoney}</del>OFF
            </p>
          )}
        </div>
      </div>
    </Link>
  );
};

export default ProductCard4;
