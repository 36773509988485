import React from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../../../Hooks/useStore";
import { baseUrl } from "../../../Hooks/Helper";
import SectionHeading4 from "../../SectionHeadings/SectionHeading4/SectionHeading4";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const Category5 = ({ categories }) => {
  const navigate = useNavigate();
  const { showDummyImage, setCategoryId } = useStore().data;

  const goPage = (id) => {
    navigate("/products");
    setCategoryId(id);
  };
  const data2 = {
    subheading: "View all",
    heading: "Category",
    paragraph: "Discovering wonder through using the product category filter.",
  };
  return (
    <section>
      <SectionWrapper>
        <SectionHeading4 data={data2} />
        <div className="grid grid-cols-3 md:grid-cols-4 md:gap-2 xl:grid-cols-6 lg:grid-cols-6 gap-2 lg:gap-4 mt-5">
          {categories?.map((category, j) => {
            return (
                <div key={j} className={`p-4 lg:p-4 text-center`}>
                  <div
                      className="p-4 cursor-pointer"
                      onClick={() => goPage(category?.id)}
                  >
                    <div className="rounded-full w-16 lg:w-32 h-16 lg:h-32 mx-auto overflow-hidden shadow mb-2">
                      {category?.image ? (
                          <img
                              onError={({ currentTarget }) =>
                                  showDummyImage({ currentTarget })
                              }
                              src={`${baseUrl.img}/${category?.image}`}
                              alt=""
                              className="w-full h-full"
                          />
                      ) : (
                          <img
                              onError={({ currentTarget }) =>
                                  showDummyImage({ currentTarget })
                              }
                              src={require("../../../assets/product-default-img/dummy.png")}
                              alt=""
                              className="w-full h-full"
                          />
                      )}
                    </div>
                    <h3 className="dark:text-white text-sm whitespace-nowrap">{category?.name}</h3>
                  </div>
                </div>
            );
          })}
        </div>
      </SectionWrapper>
    </section>
  );
};

export default Category5;