import React from 'react'
import ProductCard6 from '../../Cards/ProductCard6/ProductCard6';
import SectionHeading5 from '../../SectionHeadings/SectionHeading5/SectionHeading5';
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const FeatureProduct6 = ({data, flash}) => {
    const data2 = {
        heading: "Featured products",
        paragraph: "Pioneering Products with Unique Qualities",
    };
    return (
        <section>
            <SectionWrapper>
                <SectionHeading5 data={data2}/>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-5">
                    {data?.length > 0 &&
                        data?.slice(0, 4)?.map((product, i) => (
                            <ProductCard6 key={i} data={product} flash={product.is_on_sale === 1 ? flash : null}/>
                        ))}
                </div>
            </SectionWrapper>
        </section>
    )
}

export default FeatureProduct6