import React from "react";
import "./ProfileMenu.css";
import "rodal/lib/rodal.css";
import BreadCumb from "../BreadCumb/BreadCumb";
import SectionHeading2 from "../SectionHeadings/SectionHeading2/SectionHeading2";
import { ToastContainer } from "react-toastify";

import Promo from "./Promo/Promo";
import Menu from "./Menu/Menu";
import ProfileBanner from "./ProfileBanner/ProfileBanner";
const ProfileMenu = () => {

  const navigationData = [
    {
      page: "Home",
      route: "/",
    },
    {
      page: "Profile",
      route: "/profile",
    },
  ];

  return (
    <section className="mb-8">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="container pt-6">
        <SectionHeading2 data={{ heading: "Profile", paragraph: null }} />
        <BreadCumb data={navigationData} />
        <div className=" bg-white dark:bg-gray-800 p-4 mt-4">
          <ProfileBanner/>
          <Menu />
          <Promo />
        </div>
      </div>
    </section>
  );
};

export default ProfileMenu;
