import React, { useEffect } from 'react'
import { baseUrl } from '../../Hooks/Helper';
import SectionHeading2 from '../SectionHeadings/SectionHeading2/SectionHeading2';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllBrand } from '../../Redux/Slices/Brand/brandSlice';
const Brands = () => {

    const dispatch = useDispatch()
    const productBrands = useSelector(state => state?.brands?.brands)
    useEffect(() => {
        dispatch(fetchAllBrand())
    }, [dispatch])
    const data = {
        heading:"Our brands",
        paragraph:"People buy most from here"
    }
    return (
        <section className='py-8 lg:py-16'>
            {
                productBrands?.length>0 &&   <div className="container">
                <SectionHeading2 data={data}/>
                <div className="grid grid-cols-2 lg:grid-cols-7 gap-0 items-center mt-4">
                    {
                        productBrands?.map((brand,i)=><div key={i} className="w-full h-28 border dark:border-gray-800 p-4">
                        <img src={`${baseUrl.img}${brand?.image}`} alt="" className="w-full h-full" />
                    </div>)
                    }
                    
                </div>
            </div>
            }
           
        </section>
    );
};

export default Brands;