import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BsCart3 } from "react-icons/bs";
import { Link } from "react-router-dom";
import { baseUrl } from "../../../Hooks/Helper";
import useStore from "../../../Hooks/useStore";

const ProductCard6 = ({ data, flash }) => {
  // const navigate = useNavigate();
  const { showDummyImage } = useStore().data;
  const {
    id,
    name,
    slug,
    thumbnail_image,
    inventories_sum_stock_quantity,
    category,
    product_review_rating,
    display_price,
    previous_display_price,
  } = data;
  const discount =
    ((previous_display_price - display_price) / previous_display_price) * 100;
  const flashSalemoney = (display_price * flash?.discount) / 100;
  return (
    <div className="bg-white dark:bg-gray-900 shadow-sm rounded-xl duration-100 hover:scale-100 hover:shadow-md overflow-hidden group relative">
      <Link to={`/details/${id}/${slug}`}>
        {thumbnail_image ? (
          <div className="h-64">
            <img
            loading="lazy"
            onError={({ currentTarget }) => showDummyImage({ currentTarget })}
            src={`${baseUrl.img}${thumbnail_image}`}
            alt=""
            className=" w-auto h-full  "
          />
          </div>
        ) : (
          <img
            src={"https://placehold.co/324x256"}
            alt=""
            className="h-64 w-full object-cover rounded-t-xl"
          />
        )}
      </Link>
      {flash ? (
        <div className="absolute top-0 left-0 text-xs z-30 flex bg-red-400 text-white rounded-br-2xl p-2">
          <div className="h-5 w-14 flex items-center ">
            Save {flash?.discount.toFixed(0)}%
          </div>
        </div>
      ) : discount > 0 ? (
        <div className="absolute top-0 left-0 text-xs z-30 flex bg-red-400 text-white rounded-br-2xl p-2">
          <div className="h-5 w-14 flex items-center ">
            Save {discount.toFixed(0)}%
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="px-4 py-3 w-full">
        <div className="flex items-center justify-between">
          {category && (
            <span className="text-gray-400 mr-3 uppercase text-xs">
              {category?.name}
            </span>
          )}
          {inventories_sum_stock_quantity > 0 ? (
            <div className="text-end">
              <p className="text-green-500 font-normal text-xs whitespace-nowrap">
                In Stock
              </p>
            </div>
          ) : (
            <div className="text-end">
              <p className="text-red-500 font-normal text-xs whitespace-nowrap">
                Out of Stock
              </p>
            </div>
          )}
        </div>
        <Link to={`/details/${id}/${slug}`}>
          <p className="text-lg font-bold  whitespace-nowrap capitalize dark:text-sky-500">
            {name?.length > 15 ? name?.slice(0, 27) + "..." : name}
          </p>
        </Link>

        <p className="mb-0 flex items-center">
          {Array.from({ length: 5 }).map((_, i) => {
            if (i < parseInt(product_review_rating[0]?.avg_review_rating)) {
              return <AiFillStar key={i} className="text-orange-500" />;
            }
            return <AiOutlineStar key={i} className="text-gray-400" />;
          })}
        </p>
        <div className="flex items-center gap-1">
          {flash ? (
            <p className="text-lg font-bold text-black cursor-auto dark:text-white">
              ৳ {display_price - flashSalemoney}
            </p>
          ) : (
            <p className="text-lg font-bold text-black cursor-auto dark:text-white">
              ৳ {display_price}
            </p>
          )}

          <del className="dark:text-red-500 dark:font-bold">
            {previous_display_price && (
              <p className="text-sm text-gray-600 cursor-auto ml-2 dark:text-white">
                ৳ {previous_display_price}
              </p>
            )}
          </del>
        </div>
      </div>
      <Link to={`/details/${id}/${slug}`}  className="w-full flex items-center justify-center bg-red-400 text-white">
        <button
          className="flex items-center justify-center font-normal gap-1 p-3 text-sm dark:text-white"
     
        >
          <BsCart3 className="" size={16} /> Add to cart
        </button>
      </Link >
    </div>
  );
};

export default ProductCard6;
