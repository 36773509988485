import React from "react";
import { profileMenus } from "../../../Hooks/Helper";
import { Link, Outlet, useLocation } from "react-router-dom";

const Menu = () => {
    const pageName = useLocation().pathname;
  return (
    <div>
      <h5 className="text-slate-700 text-xl font-bold dark:text-white mt-4">
        Profile
      </h5>
      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-12 lg:col-span-3">
          <div className="grid grid-cols-1">
            {profileMenus.map((menu, i) => (
              <Link
                to={`${menu.route}`}
                className={`text-decoration-none p-3 tracking-wider text-sm ${
                  pageName === menu.route
                    ? " bg-slate-100 dark:bg-slate-500 dark:text-white"
                    : "bg-white dark:text-white dark:bg-slate-900"
                }`}
                key={i}
              >
                {menu.tabname}
              </Link>
            ))}
          </div>
        </div>

        <div className="col-span-12 lg:col-span-9">
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
