import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../../Hooks/Helper";

const initialState = {
  categories: [],
  loading: false,
  err: null,
  updates: false,
};
export const fetchAllcategories = createAsyncThunk(
  "fetchcategories/categories",
  async () => {
    const res = await fetch(`${baseUrl.url}/category`, {
      method: "GET",
      headers: {
        Authorization: baseUrl?.token,
      },
    });
    const resData = await res.json();
    return resData.data;
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setUpdate: (state, action) => {
      state.updates = !state.updates;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllcategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllcategories.fulfilled, (state, action) => {
      state.loading = false;
      state.categories = action.payload;
      state.err = null;
    });
    builder.addCase(fetchAllcategories.rejected, (state, action) => {
      state.loading = false;
      state.categories = [];
      state.err = action.payload;
    });
  },
});
export const { setUpdate } = categoriesSlice.actions;
export default categoriesSlice.reducer;
