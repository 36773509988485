import { configureStore } from "@reduxjs/toolkit";
import categoriesSliceReducer from "../Slices/category/categoriesSlice";
import bannerSliceReducer from "../Slices/Banner/bannerSlice";
import featureProductSliceReducer from "../Slices/Product/featureProductSlice";
import flashSaleSliceReducer from "../Slices/Product/flashSaleSlice";
import siteBannersReducer from "../Slices/Banner/siteBannersSlice";
import newArrivalSliceReducer from "../Slices/Product/newArrivalSlice";
import discountProductSliceReducer from "../Slices/Product/discountProductSlice";
import popularProductSliceReducer from "../Slices/Product/popularProductSlice";
import aboutSliceReducer from "../Slices/About/aboutSlice";
import cartListReducer from "../Slices/Cart/cartListSlice";
import brandReducer from "../Slices/Brand/brandSlice";
import allProductsReducer from "../Slices/Product/allProductsSlice";
import customerInfoSliceReducer from "../Slices/Customer/customerInfoSlice";
import customerOrderSliceReducer from "../Slices/Customer/customerOrderSlice";
import customerOrderDetailsSliceReducer from "../Slices/Customer/customerOrderDetailsSlice";
import sponsorReducer from "../Slices/Sponsor/sponsorSlice";
import rootReducer from "../Slices/rootSlice/rootSlice";
import themeReducer from "../Slices/theme/themeSlice";
import wishlistReducer from "../Slices/Wishlist/wishlistSlice";
import profileReducer from "../Slices/Profile/profileSlice";
import postalCodeReducer from "../Slices/Location/postalCodeSlice";
import areaReducer from "../Slices/Location/areaSlice";
const store = configureStore({
  reducer: {
    home: rootReducer,
    categories: categoriesSliceReducer,
    banner: bannerSliceReducer,
    featureProduct: featureProductSliceReducer,
    flashSale: flashSaleSliceReducer,
    siteBanners: siteBannersReducer,
    newArrival: newArrivalSliceReducer,
    discountProduct: discountProductSliceReducer,
    popularProduct: popularProductSliceReducer,
    about: aboutSliceReducer,
    cartItems: cartListReducer,
    brands: brandReducer,
    allProducts: allProductsReducer,
    customerInfo: customerInfoSliceReducer,
    customerOrder: customerOrderSliceReducer,
    customerOrderDetails: customerOrderDetailsSliceReducer,
    sponsors: sponsorReducer,
    theme: themeReducer,
    wishlist: wishlistReducer,
    profile: profileReducer,
    postalCode: postalCodeReducer,
    area: areaReducer,
  },
});
export default store;
