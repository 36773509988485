import React, {useRef, useEffect, useState} from 'react';
import {useMapsLibrary} from '@vis.gl/react-google-maps';


export const AutoCompleteMap = ({onPlaceSelect, setPosition}) => {
    const [placeAutocomplete, setPlaceAutocomplete] =
        useState(null);
    const inputRef = useRef(null);
    const places = useMapsLibrary('places');

    useEffect(() => {
        if (!places || !inputRef.current) return;

        const options = {
            fields: ["geometry", "name", "formatted_address"],
            componentRestrictions: { country: "BD" } // Restrict to Bangladesh
        };

        setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
    }, [places]);


    const handleSearchPosition = (lat, lng)=>{
        setPosition({lat: lat, lng: lng});
    }

    useEffect(() => {
        if (!placeAutocomplete) return;
        placeAutocomplete.addListener('place_changed', () => {
            onPlaceSelect(placeAutocomplete.getPlace());
            const place = placeAutocomplete.getPlace()
            onPlaceSelect(place)
            const lat = place?.geometry?.location.lat();
            const lng = place?.geometry?.location.lng();
            if(lat && lng){
                handleSearchPosition(lat, lng)
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onPlaceSelect, placeAutocomplete]);

    return (
        <div className="autocomplete-container">
            <input className="p-3 mt-2 w-[300px] outline-0 z-10 border-1" type={"search"} ref={inputRef}/>
        </div>
    );
};