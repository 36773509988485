import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
const CountDown3 = ({ endDate }) => {
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    expired: false,
  });

  useEffect(() => {
    const calculateTimeRemaining = () => {
      // Check if endDate is a valid date
      if (!endDate || isNaN(endDate)) {
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
          expired: true,
        };
      }

      const currentTime = new Date();
      const timeDiff = endDate - currentTime;

      if (timeDiff <= 0) {
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
          expired: true,
        };
      }

      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      return {
        days,
        hours,
        minutes,
        seconds,
        expired: false,
      };
    };

    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(timer);
  }, [endDate]);


  return (
    <div>
        {timeRemaining.expired ? (
          <p className="text-xl font-bold text-center text-red-500 flex items-center justify-center">
            Flash Sale Expired
          </p>
        ) : (
          <div className="flex lg:justify-between justify-evenly gap-1">
          <div className="">
            <div className=" lg:w-[82px] lg:h-[83px] w-[50px] h-[50px] rounded-md shadow bg-white dark:bg-slate-800 flex justify-center items-center">
              <span className="text-3xl font-bold font-mono  text-[#EB5757]">
   
                {timeRemaining.days}
              </span>
            </div>
            <p className="text-sm font-mono text-center leading-8">Days</p>
          </div>
          <div className="">
            <div className="  lg:w-[82px] lg:h-[83px] w-[50px] h-[50px] rounded-md shadow bg-white dark:bg-slate-800 flex justify-center items-center">
              <span className=" text-3xl font-bold font-mono  text-[#2F80ED]">
         
                {timeRemaining.hours}
              </span>
            </div>
            <p className="text-sm font-mono text-center leading-8">Hours</p>
          </div>
          <div className="">
            <div className=" lg:w-[82px] lg:h-[83px] w-[50px] h-[50px] rounded-md shadow bg-white dark:bg-slate-800 flex justify-center items-center">
              <span className="  text-3xl font-bold font-mono   text-[#219653]">
       
                {timeRemaining.minutes}
              </span>
            </div>
            <p className="text-sm font-mono text-center leading-8">Minutes</p>
          </div>
          <div className="">
            <div className=" lg:w-[82px] lg:h-[83px] w-[50px] h-[50px] rounded-md shadow bg-white dark:bg-slate-800 flex justify-center items-center">
              <span className=" text-3xl font-bold font-mono  text-[#EF5DA8]">
       
                {timeRemaining.seconds}
              </span>
            </div>
            <p className="text-sm font-mono text-center leading-8">Seconds</p>
          </div>
        </div>
        )}

    
    </div>
  );
};

export default CountDown3;
