import React from "react";
import ProductContainer5 from "../../ProductContainers/ProductContainer5/ProductContainer5";
import {baseUrl} from "../../../Hooks/Helper";


const FeatureProduct9 = ({data, site_banners, flash}) => {


    return (
        <section className="py-8 lg:py-16">
            <div className="w-full h-fit bg-gray-100 dark:bg-gray-900 relative p-4 lg:p-0">
                <div className="xl:h-[500px] xl:w-full hidden lg:block">
                    <img
                        src={
                            `${site_banners?.featured_product_image ? `${baseUrl.img}/${site_banners?.featured_product_image}` :
                                "https://placehold.co/1905x500"}`}
                        alt=""
                        className="w-full h-full object-contain hidden lg:block"
                    />
                </div>
                <div
                    className="relative lg:absolute w-full h-full xl:top-0 xl:left-0 lg:top-[-40px] lg:left-0 flex items-center justify-center">
                    <div>
                        {/*<h2 className="text-2xl lg:text-3xl font-bold text-gray-800 dark:text-gray-300">*/}
                        {/*    Trusted and Treasured by Many*/}
                        {/*</h2>*/}
                        {/*<h4 className="text-xl lg:text-2xl font-bold text-gray-800 dark:text-gray-300 ">*/}
                        {/*    Grab your own style*/}
                        {/*</h4>*/}
                        {/*<div className="w-full lg:w-5/12 mx-auto">*/}
                        {/*    <p className="text-sm text-gray-800 dark:text-gray-300 ">*/}
                        {/*        Your will have a pleasant shopping experience with our shop,*/}
                        {/*        which can be used for many other sorts of shops.*/}
                        {/*    </p>*/}
                        {/*</div>*/}
                        {/*<div className="flex justify-center mt-3">*/}
                        {/*    <Button*/}
                        {/*        classes={""}*/}
                        {/*        size={"md"}*/}
                        {/*        title={"SHOP NOW"}*/}
                        {/*        func={() => navigate("/products")}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <ProductContainer5 data={data} flash={flash}/>
        </section>
    );
};

export default FeatureProduct9;
