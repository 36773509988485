import React from 'react'
import {useNavigate} from 'react-router-dom';
import ProductCard7 from '../../Cards/ProductCard7/ProductCard7';
import SectionHeading7 from '../../SectionHeadings/SectionHeading7/SectionHeading7';
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const DiscountProduct10 = ({data, flash}) => {
    const navigate = useNavigate()
    const goAllPage = () => {
        navigate("/products")
    };
    const pageHeading = {
        heading: "Discount Dash",
        paragraph: "Grab the Best Deals in Town!",
        action: goAllPage,
        show: data?.length > 7
    }
    return (

        <section>
            <SectionWrapper>
                {
                    data?.length > 0 && <div>
                        <SectionHeading7 data={pageHeading}/>
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-3">
                            {
                                data?.slice(0, 10)?.map((product, i) => <div key={i}>
                                    <ProductCard7 product={product} flash={product.is_on_sale === 1 ? flash : null}/>
                                </div>)
                            }
                        </div>
                    </div>
                }
            </SectionWrapper>
        </section>
    )
}

export default DiscountProduct10