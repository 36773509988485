import React from 'react';

const NoContent = () => {
    return (
        <div>
            <div className="container">
                <div className='text-center'>
                    <div style={{height:'220px',width:'220px'}} className="mx-auto">
                        <img className='w-100' src={require("../../assets/no-content/empty.png")} alt="" />
                    </div>
                    <h5 className='primary-text-color m-0 font-style1 font2'>No content</h5>
                    <p className='font4 secondary-color'>it may seems no content available now</p>
                </div>
            </div>
        </div>
    );
};

export default NoContent;