import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { Link } from "react-router-dom";
import { baseUrl } from "../../../Hooks/Helper";
const ProductCard7 = ({ product, flash }) => {
  const {
    id,
    slug,
    product_review_rating,
    name,
    thumbnail_image,
    display_price,
    previous_display_price,
    inventories_sum_stock_quantity,
    category,
  } = product;
  const discountPercentage =
    ((previous_display_price - display_price) / previous_display_price) * 100;
  const flashSalemoney = (display_price * flash?.discount) / 100;
  return (
    <div className="font-mono">
      <div className="bg-white dark:bg-gray-900 max-h-[300px] w-full rounded-md p-2">
        <div className="flex justify-center items-center leading-none">
          <Link to={`/details/${id}/${slug}`}>
            {thumbnail_image ? (
             <div className="h-[180px] w-full">
               <img
                src={`${baseUrl.img}${thumbnail_image}`}
                alt="pic"
                className="h-full w-full rounded-md  transform transition duration-700 hover:scale-105"
              />
             </div>
            ) : (
             
              <div className="h-[180px] w-full">
               <img
                src={"https://placehold.co/160x160"}
                alt="pic"
                className="h-full w-full rounded-md  transform transition duration-700 hover:scale-105"
              />
             </div>
            )}
          </Link>
        </div>
        <Link className="" to={`/details/${id}/${slug}`}>
          <p className="block text-sm font-bold dark:text-sky-500">
            {name?.length > 15 ? name?.slice(0, 20) + "..." : name}
          </p>

          <div className="flex justify-between">
            <span className="my-1 flex">
              <p className="mb-0 flex items-center">
                {Array.from({ length: 5 }).map((_, i) => {
                  if (
                    i < parseInt(product_review_rating[0]?.avg_review_rating)
                  ) {
                    return <AiFillStar key={i} className="text-orange-500" />;
                  }
                  return <AiOutlineStar key={i} className="text-gray-400" />;
                })}
              </p>
            </span>
            {category && (
              <p className="inline text-xs text-slate-700 dark:text-white">
                {category.name}
              </p>
            )}
          </div>
        </Link>
        <div className="flex justify-between">
          {flash ? (
            <p className="text-lg dark:text-white">
              ৳ {display_price - flashSalemoney}
            </p>
          ) : (
            <p className="text-lg dark:text-white">৳ {display_price}</p>
          )}
          {flash ? (
            <p className="dark:text-gray-400">
              {flash?.discount.toFixed(0)}% OFF
            </p>
          ) : previous_display_price ? (
            <p className="dark:text-gray-400">
              {discountPercentage.toFixed(0)}% OFF
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="">
          {inventories_sum_stock_quantity > 0 ? (
            <p className="text-green-500 font-normal text-xs">In Stock</p>
          ) : (
            <p className="text-red-500 font-normal text-xs">Out of Stock</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard7;
