import React from "react";
import "./FlashSale4.css";
import ProductCard4 from "../../Cards/ProductCard4/ProductCard4";
import SectionHeading from "../../SectionHeadings/SectionHeading/SectionHeading";
import CoutDown from "../../CountDowns/CoutDown/CoutDown";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const FlashSale4 = ({data, flash}) => {

    const data2 = {
        heading: "Flash Sale",
        paragraph: "Purchasing Premium Items During a Flash Sale",
        show: data?.length > 7
    };

    const endDate = new Date(flash?.end_date);
    return (
        <section>
            <SectionWrapper>
                {data?.length > 0 && (
                    <div>
                        <SectionHeading data={data2}/>
                        <div className="w-full lg:w-3/12 mt-4">
                            <CoutDown endDate={endDate} data={data}/>
                        </div>

                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-3 ">
                            {data?.slice(0, 10)?.map((item, i) => (
                                <ProductCard4 key={i} data={item} flash={flash ? flash : null}/>
                            ))}
                        </div>
                    </div>
                )}
            </SectionWrapper>
        </section>
    );
};

export default FlashSale4;
