import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import GlobalProvider from "./GlobalProvider/GlobalProvider";
import store from "./Redux/Store/store";
import { HelmetProvider } from 'react-helmet-async';
import 'react-loading-skeleton/dist/skeleton.css'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <GlobalProvider>
          <HelmetProvider>
              <App />
          </HelmetProvider>
      </GlobalProvider>
    </Provider>
   // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
