import React from "react";
import { baseUrl } from "../../../Hooks/Helper";
import "swiper/swiper-bundle.min.css";
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
const Slider6 = ({ banners }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="overflow-hidden">
        <Swiper
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 10500,
            disableOnInteraction: false,
          }}
          modules={[Navigation, Autoplay]}
        >
          <div>
            {banners?.length > 0 ? (
              <div>
                {banners?.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className="bg-cover bg-center h-64 lg:h-[400px] relative"
                      style={{
                        backgroundImage: `url(${baseUrl.img}/${slide.image})`,
                      }}
                    >
                      <div className="container h-full flex items-center">
                        {slide.title && (
                          <div className="">
                            <h2 className="text-black text-3xl lg:text-7xl font-bold">
                              {slide.title}
                            </h2>
                            <h2 className="text-black text-xl font-mono my-2 lg:my-4">
                              {slide.subtitle}
                            </h2>
                            <button
                              className="px-6 py-3 hover:text-white text-sm text-white bg-red-400"
                              onClick={() => navigate("/products")}
                            >
                              {slide?.button_text}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </div>
            ) : (
              <div>
                {Array.from({ length: 5 }).map((_, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="bg-cover bg-center h-64 lg:h-[400px] relative">
                        <img
                          src="https://placehold.co/1900x400"
                          alt=""
                          className="w-full h-full object-cover"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </div>
            )}
          </div>

          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </Swiper>
      </div>
    </div>
  );
};

export default Slider6;
