import React, {useEffect, useState} from "react";
import {baseUrl} from "../../../Hooks/Helper";
import Skeleton from "react-loading-skeleton";

const SiteBanner = () => {
    const [banner, setBanner] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getSiteBanner = async () => {
            setIsLoading(true);
            try {
                const res = await fetch(`${baseUrl.url}/banners`, {
                    method: "GET",
                    headers: {
                        Authorization: baseUrl.token,
                    },
                });
                const resData = await res.json();
                if (res.ok) {
                    setBanner(resData?.data?.all_product_side_image);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error("error", error);
            } finally {
                setIsLoading(false);
            }
        };
        getSiteBanner().then();
    }, []);
    return (
        <div>
            {
                isLoading ? <Skeleton
                    width="100%"
                    height="256px"
                /> : <div className="h-32 lg:h-64 mt-6">
                    {
                        banner ? (
                            <img
                                src={`${baseUrl.img}${banner}`}
                                alt=""
                                className="w-full h-full object-cover"
                            />
                        ) : (
                            <img
                                src={"https://placehold.co/1344x256"}
                                alt=""
                                className="w-full h-full object-cover"
                            />
                        )
                    }
                </div>
            }
        </div>
    );
};

export default SiteBanner;
