import React from "react";
import {baseUrl} from "../../../Hooks/Helper";
import SectionHeading7 from "../../SectionHeadings/SectionHeading7/SectionHeading7";
import {useNavigate} from "react-router-dom";
import useStore from "../../../Hooks/useStore";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const Category10 = ({categories}) => {
    const pageHeading = {
        heading: "Our Categories",
        paragraph: "Diving Deep into Category-Based Shopping",
        action: null,
        show: false,
    };

    const {setCategoryId} = useStore().data;
    const navigate = useNavigate();
    const goPage = (id) => {
        navigate("/products");
        setCategoryId(id);
    };

    return (
        <section>
            <SectionWrapper>
                <SectionHeading7 data={pageHeading}/>
                <div className="grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 gap-4 mt-5">
                    {categories?.map((item, i) => (
                        <div className="text-center" key={i} onClick={() => goPage(item?.id)}>
                            <div className="w-16 mx-auto">
                                {item?.image ? (
                                    <img
                                        src={`${baseUrl?.img}/${item?.image}`}
                                        alt=""
                                        className="w-full"
                                    />
                                ) : (
                                    <img
                                        src={`https://placehold.co/64x64`}
                                        alt=""
                                        className="w-full"
                                    />
                                )}
                            </div>
                            <span className="dark:text-white text-sm whitespace-nowrap">{item?.name}</span>
                        </div>
                    ))}
                </div>
            </SectionWrapper>
        </section>
    );
};

export default Category10;