import React, { useEffect, useState } from "react";
import { baseUrl } from "../../Hooks/Helper";
import useStore from "../../Hooks/useStore";
import AccordionItem from "../../Components/AccordionItem/AccordionItem";
import { BsSearch } from "react-icons/bs";
import PageHeading from "../../Components/PageHeading/PageHeading";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeData } from "../../Redux/Slices/rootSlice/rootSlice";
import Subscription from "../../Components/Subscriptions/Subscription/Subscription";
import SectionHeading2 from "../../Components/SectionHeadings/SectionHeading2/SectionHeading2";
import BreadCumb from "../../Components/BreadCumb/BreadCumb";

const Faq = () => {
  const [faqData, setFaqData] = useState();
  const [openIndex, setOpenIndex] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [filteredFaqData, setFilteredFaqData] = useState(null);
  const { customerToken } = useStore().data;

  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.home);
  useEffect(() => {
    dispatch(fetchHomeData());
  }, [dispatch]);

  useEffect(() => {
    const getFaqInfo = async () => {
      const res = await fetch(`${baseUrl.url}/faq-list`, {
        method: "GET",
        headers: {
          Authorization: `${baseUrl.token}`,
        },
      });
     
      if (res.ok && res.status!==204) {
        const resData = await res.json();
        setFaqData(resData.data);
      }
    };
    getFaqInfo().then();
  }, [customerToken]);

  useEffect(() => {
    if (searchInput.trim() === "") {
      setFilteredFaqData(null);
    } else {
      const filteredData = faqData?.filter((faq) =>
        faq.question.toLowerCase().includes(searchInput.toLowerCase())
      );
      setFilteredFaqData(filteredData);
    }
  }, [searchInput, faqData]);

  const toggleAccordion = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const navigationData = [
    {
      page: "Home",
      route: "/",
    },
    {
      page: "Faq",
      route: "/faq",
    },
   
  ];
  const data2 = {
    heading: "Faq",
    // paragraph: "Frequently Ask Questions",
  };
  return (
    <div>
        
      <section className="py-8 lg:py-16">
   
        <div className="container">
        <SectionHeading2 data={data2} />
          <BreadCumb data={navigationData} />
          {faqData?.length>0 ? (
            <div className="w-full lg:w-4/6 mx-auto">
              <div className="text-center">
                <PageHeading
                  h1={"FAQs"}
                  h2={"Frequently Asked Questions"}
                  h3={"Have questions? We are here to help."}
                />
                <div className="grid grid-cols-12 w-[300px] mx-auto items-center border p-2 pl-3 rounded my-8">
                  <div className="col-span-2">
                    <BsSearch className="dark:text-white" />
                  </div>
                  <div className="col-span-10">
                    <input
                      type="text"
                      placeholder="Search question"
                      className="w-full bg-transparent focus:outline-none"
                      value={searchInput}
                      onChange={handleSearchInputChange}
                      onKeyDown={handleSearchKeyPress}
                    />
                  </div>
                </div>
              </div>
              {filteredFaqData !== null
                ? filteredFaqData.map((faq, index) => (
                    <AccordionItem
                      key={index}
                      question={faq.question}
                      answer={faq.answer}
                      isOpen={openIndex === index}
                      toggleAccordion={() => toggleAccordion(index)}
                    />
                  ))
                : faqData?.map((faq, index) => (
                    <AccordionItem
                      key={index}
                      question={faq.question}
                      answer={faq.answer}
                      isOpen={openIndex === index}
                      toggleAccordion={() => toggleAccordion(index)}
                    />
                  ))}
            </div>
          ) : (
            <div className="flex items-center justify-center">
              <div>
                <div className="w-full h-52">
                  <img
                    src="https://img.freepik.com/free-vector/tiny-business-people-with-giant-faq-letters-gadget-users-searching-instructions-useful-information-flat-vector-illustration-customer-support-solution-concept-banner-landing-web-page_74855-23409.jpg?w=1380&t=st=1696580798~exp=1696581398~hmac=0964467cd7b132ab492f6afb68da174c69c7f53f55d1e0e0cc102c339d79c164"
                    className="w-full h-full"
                    alt="faq"
                  />
                </div>
                <p className="dark:text-white flex items-center justify-center">
                  No Faq questions at this moments
                </p>
              </div>
            </div>
          )}
        </div>
      </section>
      <Subscription data={data?.site_banners} />
    </div>
  );
};

export default Faq;
