import React from "react";
import "./Loader2.css";
const Loader2 = () => {
  return (
    <div className="h-screen w-full flex items-center justify-center">
      <div className="w-16 h-16">
        <img
          src="https://i.gifer.com/ZKZg.gif"
          className="w-full h-full"
          alt=""
        />
      </div>
    </div>
  );
};

export default Loader2;
