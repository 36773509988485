// import React, { useEffect, useState } from "react";
// const CoutDown = ({ endDate }) => {
//   const [timeRemaining, setTimeRemaining] = useState({
//     days: 0,
//     hours: 0,
//     minutes: 0,
//     seconds: 0,
//     expired: false,
//   });

//   useEffect(() => {
//     const calculateTimeRemaining = () => {
//       const currentTime = new Date();
//       const timeDiff = endDate - currentTime;

//       if (timeDiff <= 0) {
//         return {
//           days: 0,
//           hours: 0,
//           minutes: 0,
//           seconds: 0,
//           expired: true,
//         };
//       }

//       const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
//       const hours = Math.floor(
//         (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
//       );
//       const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
//       const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

//       return {
//         days,
//         hours,
//         minutes,
//         seconds,
//         expired: false,
//       };
//     };

//     const timer = setInterval(() => {
//       setTimeRemaining(calculateTimeRemaining());
//     }, 1000);

//     return () => clearInterval(timer);
//   }, [endDate]);

//   return (
//     <div>
    
//       <div className="text-start ">
//         {/* <div>
//             <p>{data?.flash_sale?.title}</p>
//             <p>{data?.flash_sale?.short_description}</p>
//         </div> */}
//         <div className="text-xl font-bold text-center gap-1 grid grid-cols-4 relative z-40">
//           <div className="border bg-white dark:bg-blue-500 dark:border-none dark:text-white p-1 rounded text-gray-700">
//             {timeRemaining.days < 10 && timeRemaining.days > -1 && "0"}
//             {timeRemaining.days}
//             <p className="font-normal block text-xs">Days</p>
//           </div>
//           <div className="border bg-white dark:bg-pink-500 dark:border-none dark:text-white p-1 rounded text-gray-700">
//             {timeRemaining.hours < 10 && timeRemaining.hours > -1 && "0"}
//             {timeRemaining.hours}
//             <p className="font-normal block text-xs">Hour</p>
//           </div>
//           <div className="border bg-white dark:bg-green-500 dark:border-none dark:text-white p-1 rounded text-gray-700">
//             {timeRemaining.minutes < 10 && timeRemaining.minutes > -1 && "0"}
//             {timeRemaining.minutes}
//             <p className="font-normal block text-xs">Min</p>
//           </div>
//           <div className="border bg-white dark:bg-orange-500 dark:border-none dark:text-white p-1 rounded text-gray-700">
//             {timeRemaining.seconds < 10 && timeRemaining.seconds > -1 && "0"}
//             {timeRemaining.seconds}
//             <p className="font-normal block text-xs">Sec</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CoutDown;


import React, { useEffect, useState } from "react";

const CountDown = ({ endDate }) => {
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    expired: false,
  });

  useEffect(() => {
    const calculateTimeRemaining = () => {
      // Check if endDate is a valid date
      if (!endDate || isNaN(endDate)) {
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
          expired: true,
        };
      }

      const currentTime = new Date();
      const timeDiff = endDate - currentTime;

      if (timeDiff <= 0) {
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
          expired: true,
        };
      }

      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      return {
        days,
        hours,
        minutes,
        seconds,
        expired: false,
      };
    };

    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(timer);
  }, [endDate]);


  return (
    <div>
      <div className="text-start">
        {timeRemaining.expired ? (
          <p className="text-xl font-bold text-center text-red-500 flex items-center justify-center">
            Flash Sale Expired
          </p>
        ) : (
          <div className="text-xl font-bold text-center gap-1 grid grid-cols-4 relative z-40">
            <div className="border bg-white dark:bg-blue-500 dark:border-none dark:text-white p-1 rounded text-gray-700">
              
              {timeRemaining.days}
              <p className="font-normal block text-xs">Days</p>
            </div>
            <div className="border bg-white dark:bg-pink-500 dark:border-none dark:text-white p-1 rounded text-gray-700">
              
              {timeRemaining.hours}
              <p className="font-normal block text-xs">Hour</p>
            </div>
            <div className="border bg-white dark:bg-green-500 dark:border-none dark:text-white p-1 rounded text-gray-700">
            
              {timeRemaining.minutes}
              <p className="font-normal block text-xs">Min</p>
            </div>
            <div className="border bg-white dark:bg-orange-500 dark:border-none dark:text-white p-1 rounded text-gray-700">
            
              {timeRemaining.seconds}
              <p className="font-normal block text-xs">Sec</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CountDown;

