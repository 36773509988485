import {  useState } from "react";

const useProvider = () => {
    const [activeTab,setActiveTab] = useState('Profile') 
   const [customize,setCustomize] = useState(false)
   const [save,setSave] = useState(false)
    const [cartPopUp,setCartPopUp] = useState(false)
    return {
    activeTab,setActiveTab,save,setSave,customize,setCustomize,cartPopUp,setCartPopUp
    };
};

export default useProvider;