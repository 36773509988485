import React from "react";
import {BsArrowRight} from "react-icons/bs";
import {useNavigate} from "react-router-dom";
import ProductCard5 from "../../Cards/ProductCard5/ProductCard5";
import SectionHeading4 from "../../SectionHeadings/SectionHeading4/SectionHeading4";
import CoutDown from "../../CountDowns/CoutDown/CoutDown";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const FlashSale5 = ({data, flash}) => {

    const navigate = useNavigate();
    const data2 = {
        subheading: "",
        heading: "Savings in a Flash ",
        paragraph: " Must-Have Products at Unbeatable Prices",
    };
    const endDate = new Date(flash?.end_date);
    return (
        <section>
            <SectionWrapper>
                {data?.length > 0 && (
                    <div>
                        <SectionHeading4 data={data2}/>
                        <div className="w-full lg:w-3/12 mt-4 mx-auto">
                            <CoutDown endDate={endDate} data={data}/>
                        </div>
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-3">
                            {data?.length > 0 &&
                                data
                                    ?.slice(0, 8)
                                    ?.map((product, i) => (
                                        <ProductCard5
                                            key={i}
                                            data={product}
                                            flash={flash ? flash : null}
                                        />
                                    ))}
                        </div>
                        {data?.products?.length > 7 && (
                            <div className="text-center">
                                <button
                                    onClick={() => navigate("/products")}
                                    className="py-3 px-6 bg-gray-100 hover:bg-gray-200 transition-all duration-300"
                                >
                <span className="flex items-center">
                  View all <BsArrowRight className="ml-2"/>
                </span>
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </SectionWrapper>
        </section>
    );
};

export default FlashSale5;
