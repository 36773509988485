import React from 'react';
import './SectionHeading2.css'
import { BsArrowRight } from "react-icons/bs";
const SectionHeading2 = ({data}) => {
    const {heading,paragraph,action,show} = data
    const headings = heading.split(" ")
    return (
        <div className='section-heading2 flex items-center justify-between'>
            <div>
                <h5 className={`text-2xl font-bold flex items-center`}><span className='text-gray-800 dark:text-white'>{headings[0]}</span><span className='ml-2 text-gray-800 dark:text-white'>{headings[1]}</span></h5>
                {(paragraph!==null) && <p className='text-sm dark:text-white'>{paragraph}</p>}
            </div>
            {
                show ? <button className='p-1 px-3  border-0 flex items-center rounded-full text-red-400 ' onClick={action}>View all<BsArrowRight/></button> :''
            }
        </div>
    );
};

export default SectionHeading2;