import React from "react";
import { baseUrl } from "../../../Hooks/Helper";
const Slider7 = ({ banners }) => {
  return (
    <section
      className="p-0 lg:p-8 bg-cover"
      style={{
        backgroundImage:
          `url('https://demo2.wpopal.com/ekommart/wp-content/uploads/2020/02/h14_bg1.jpg)` ||
          `url("https://placehold.co/1900x400")`,
      }}
    >
      <div className="container pt-4">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-4">
            <div className="h-[600px] w-full">
              {banners?.length > 0 && banners[0]?.image ? (
                <img
                  src={`${baseUrl.img}/${banners[0]?.image}`}
                  alt=""
                  className="w-auto h-full object-cover"
                />
              ) : (
                <img
                  src={"https://placehold.co/437x600"}
                  alt=""
                  className="w-full h-full object-cover"
                />
              )}
            </div>
          </div>
          <div className="col-span-12 lg:col-span-8">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 lg:col-span-4">
                <div className="h-[600px] w-full">
                  {banners?.length > 0 && banners[1]?.image ? (
                    <img
                      src={`${baseUrl.img}/${banners[1]?.image}`}
                      alt=""
                      className="w-auto h-full object-cover"
                    />
                  ) : (
                    <img
                      src={"https://placehold.co/287x600"}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  )}
                </div>
              </div>
              <div className="col-span-12 lg:col-span-8">
                <div className="grid grid-cols-1 gap-4">
                  <div className="col-span-12">
                    <div className="h-[250px] w-full">
                      {banners?.length > 0 && banners[2]?.image ? (
                        <img
                          src={`${baseUrl.img}/${banners[2]?.image}`}
                          alt=""
                          className="w-auto h-full object-cover"
                        />
                      ) : (
                        <img
                          src={"https://placehold.co/589x250"}
                          alt=""
                          className="w-full h-full object-cover"
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="h-[334px] w-full">
                      {banners?.length > 0 && banners[3]?.image ? (
                        <img
                          src={`${baseUrl.img}/${banners[3]?.image}`}
                          alt=""
                          className="w-auto h-full object-cover"
                        />
                      ) : (
                        <img
                          src={"https://placehold.co/589x334"}
                          alt=""
                          className="w-auto h-full object-cover"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Slider7;
