import React from 'react'
import './NoData.css'
const NoData = ({status,err,errDetails}) => {

  return (
    <div className='gradient-primary h-screen flex items-center justify-center w-full'>
        <div className='flex items-center'>
            <h1 className='text-5xl dark:text-white'>{status}</h1>
            <div className='ml-2 pl-3 border-l-2 border-black dark:border-gray-500'>
                <h2 className='text-xl dark:text-gray-200'>{err}</h2>
                <p className='text-sm text-gray-600'>{errDetails}</p>
            </div>
        </div>
    </div>
  )
}

export default NoData