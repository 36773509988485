import React from 'react';
import './PageBanner.css'
import BreadCumb from '../../Components/BreadCumb/BreadCumb';

const PageBanner = ({navigationData}) => {
    return (
        <div>
            <div className="container">
                <div className="pt-2">
                    {/* <h4 className='m-0 p-0'>{location.split("/")[1].charAt(0).toUpperCase() + location.slice(2)}</h4> */}
                    <BreadCumb data={navigationData}/>
                </div>
            </div>
        </div>
    );
};

export default PageBanner;