import React, {useEffect} from 'react';
import SectionHeading2 from '../SectionHeadings/SectionHeading2/SectionHeading2';
import {useParams} from 'react-router-dom';
import useStore from '../../Hooks/useStore';
import {baseUrl} from '../../Hooks/Helper';
import ProductsContainer from '../ProductContainers/ProductsContainer/ProductsContainer';
import {useDispatch, useSelector} from 'react-redux';
import {fetchHomeData} from '../../Redux/Slices/rootSlice/rootSlice';

const SimillarProducts = () => {
    const dispatch = useDispatch();
    const {data} = useSelector((state) => state.home);
    const {id} = useParams()
    const {
        filteredProducts,
        setFilteredProducts,
        productDetails,
    } = useStore().data;

    useEffect(() => {
        dispatch(fetchHomeData());
    }, [dispatch]);


    useEffect(() => {
        const catId = productDetails?.category_id;
        if (catId) {
            const fetchFilterProducts = async () => {
                let url = `${baseUrl.url}/product-filter?per_page=15`

                if (catId) {
                    url += `&category_id=${catId}`
                }
                const res = await fetch(
                    url,
                    {
                        method: "GET",
                        headers: {
                            Authorization: baseUrl.token,
                        },
                    }
                );
                const resData = await res.json();
                if (res.ok && resData.status !== 204) {
                    const filteredData = resData.data.data.filter(
                        (product) => product.id !== Number(id)
                    );
                    setFilteredProducts(filteredData);
                }
            };
            fetchFilterProducts().then();
        }
    }, [productDetails,setFilteredProducts, id]);

    const data2 = {
        heading: "Similar products",
        paragraph: "You may also like this products"
    }
    return (
        <div className="py-8 lg:py-16">
            <div className="container">
                <SectionHeading2 data={data2}/>
            </div>
            <ProductsContainer data={filteredProducts} flash={data?.flash_sale}/>
        </div>
    );
};

export default SimillarProducts;