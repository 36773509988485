import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay } from "swiper";
import "swiper/css/bundle";
import { baseUrl } from "../../../Hooks/Helper";

const Slider3 = ({ banners }) => {
  return (
    <section className="pb-8 lg:pb-16">
      <div>
        <Swiper
          style={{ height: "100%" }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {
            banners?.length>0 ? <div>
              {banners?.length > 0 &&
            banners?.map((banner, i) => (
              <SwiperSlide key={i}>
                <div>
                  <img
                    src={`${baseUrl.img}${banner?.image}`
                    }
                    alt="slider"
                    style={{ objectFit: "cover" }}
                    className="w-full h-full"
                  />
                </div>
              </SwiperSlide>
            ))}
            </div>:<div>
            {Array.from({ length: 5 }).map((_, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div>
                        <img
                          src="https://placehold.co/1905x1195"
                          style={{ objectFit: "cover" }}
                          className="w-full h-full object-cover"
                          alt="slider"

                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
            </div>
          }
        </Swiper>
      </div>
    </section>
  );
};

export default Slider3;
