import React from "react";
import ProductCard6 from "../../Cards/ProductCard6/ProductCard6";
import SectionHeading5 from "../../SectionHeadings/SectionHeading5/SectionHeading5";
import CoutDown from "../../CountDowns/CoutDown/CoutDown";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const FlashSale6 = ({data, flash}) => {
    const data2 = {
        subheading: "",
        heading: "Limited-Time Luxury ",
        paragraph: "Your Gateway  to Bargain Buys",
    };
    const endDate = new Date(flash?.end_date);
    return (
        <section>
            <SectionWrapper>
                {data?.length > 0 && (
                    <div>
                        <SectionHeading5 data={data2}/>
                        <div className="w-full lg:w-3/12 mt-4 mx-auto">
                            <CoutDown endDate={endDate} data={data}/>
                        </div>
                        <div className=" grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-3">
                            {data?.length > 0 &&
                                data
                                    ?.slice(0, 8)
                                    ?.map((product, i) => (
                                        <ProductCard6
                                            key={i}
                                            data={product}
                                            flash={flash ? flash : null}
                                        />
                                    ))}
                        </div>
                    </div>
                )}
            </SectionWrapper>
        </section>
    );
};

export default FlashSale6;
