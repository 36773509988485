import React from "react";
import ProductCard from "../../Cards/ProductCard/ProductCard";
import SectionHeading3 from "../../SectionHeadings/SectionHeading3/SectionHeading3";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const FeatureCard3 = ({data, flash}) => {

    return (
        <section>
            <SectionWrapper>
                <SectionHeading3
                    title={"Featured products"}
                    text={"Exploring Interesting Product Features"}
                />
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-1 lg:gap-4 mt-5">
                    {data?.length > 0 &&
                        data?.map((product, i) => (
                            <ProductCard key={i} product={product} flash={product.is_on_sale === 1 ? flash : null}/>
                        ))}
                </div>
            </SectionWrapper>
        </section>
    );
};

export default FeatureCard3;
