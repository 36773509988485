// import React from 'react'
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay } from "swiper";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "swiper/css/bundle";
// import { baseUrl } from '../../../Hooks/Helper';
// import SectionHeading7 from '../../SectionHeadings/SectionHeading7/SectionHeading7';
// const Sponsor10 = ({data}) => {
//     const pageHeading = {
//         heading:"Partnerships",
//         paragraph:"Celebrating Our Sponsors",
//         action:null,
//         show:false
//     }
//   return (
//     <section className="py-8 lg:py-16">
//     <div className="container">
//       <SectionHeading7 data={pageHeading} />
//       <div className='mt-3'>
//         <Swiper
//           breakpoints={{
//             400: {
//               slidesPerView: 1,
//               spaceBetween: 20,
//             },
//             640: {
//               slidesPerView: 2,
//               spaceBetween: 20,
//             },
//             768: {
//               slidesPerView: 4,
//               spaceBetween: 0,
//             },
//             1024: {
//               slidesPerView: 5,
//               spaceBetween: 0,
//             },
//           }}
//            autoplay={{
//             delay: 5500,
//             disableOnInteraction: false,
//           }}
//         loop={true}
//         modules={[ Autoplay]}
//         >
//           {data?.length > 0 &&
//             data?.map((sponsor, i) => (
//               <SwiperSlide key={i}>
//                 <div className='bg-white h-32 w-full'>
//                   <img
//                     src={`${baseUrl.img}/${sponsor?.image}` ||"https://placehold.co/219x105"}
//                     alt="slider"
//                     className="w-auto h-full mx-auto"
//                   />
//                 </div>
//               </SwiperSlide>
//             ))}
//         </Swiper>
//       </div>
//     </div>
//   </section>
//   )
// }

// export default Sponsor10

import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/bundle";
import {baseUrl} from "../../../Hooks/Helper";
import SectionHeading7 from "../../SectionHeadings/SectionHeading7/SectionHeading7";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const Sponsor10 = ({data}) => {
    const pageHeading = {
        heading: "Partnerships",
        paragraph: "Celebrating Our Sponsors",
        action: null,
        show: false,
    };

    return (
        <section>
            <SectionWrapper>
                <SectionHeading7 data={pageHeading}/>
                <div className="mt-5">
                    <Swiper
                        breakpoints={{
                            400: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 4,
                                spaceBetween: 0,
                            },
                            1024: {
                                slidesPerView: 6,
                                spaceBetween: 0,
                            },
                        }}
                        autoplay={{
                            delay: 5500,
                            disableOnInteraction: false,
                        }}
                        loop={true}
                        modules={[Autoplay]}
                    >
                        {data?.length > 0 ? (
                            data.map((sponsor, i) => (
                                <SwiperSlide key={i}>
                                    <div className=" h-[126px] w-full">
                                        <img
                                            src={
                                                sponsor?.image
                                                    ? `${baseUrl.img}/${sponsor.image}`
                                                    : "https://placehold.co/269x128"
                                            }
                                            alt="slider"
                                            className="w-auto h-full mx-auto"
                                        />
                                    </div>
                                </SwiperSlide>
                            ))
                        ) : (
                            <SwiperSlide>
                                <div className=" h-[126px] w-full">
                                    <img
                                        src="https://placehold.co/210x126"
                                        alt="placeholder"
                                        className="w-auto h-full mx-auto"
                                    />
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
            </SectionWrapper>
        </section>
    );
};

export default Sponsor10;
