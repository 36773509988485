import React from 'react';

const Button = ({title=null,classes,size,submit=false,func=null,icon=null,type=null,disable=false}) => {
    const sm = 'px-2 py-1 text-sm';
    const md = 'px-4 py-2 text-base';
    const xl = 'px-5 py-3 text-xl';
    const finalSize = size==="sm" ? sm : size==="md" ? md : xl;
    const finalType = type==="delete" ? "bg-red-500 hover:bg-red-600" : "bg-red-400 hover:bg-red-500"
    return (
        <button type={submit ? "submit" : "button"} disabled={disable} onClick={func} className={`rounded-sm transition-all duration-200 flex items-center justify-center text-white shadow ${finalType} ${finalSize} ${classes}`}>{icon}{title}</button>
    );
};

export default Button;