import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl, errorNotify, successNotify } from "../../../Hooks/Helper";
import { AiOutlineMail } from "react-icons/ai";
import Button from "../../Button/Button";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
const Subscription = ({data}) => {
  const [email, setEmail] = useState("");
  const backgroundStyle = {
    backgroundImage: data?.newsletter_image ?  `linear-gradient(90deg, rgba(227,227,227,0.3), rgba(172,172,172,0.3)), url(${baseUrl.img}/${data?.newsletter_image})` :"url('https://placehold.co/1344x307')",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  const handleSubmit = async () => {
    if (email.length < 7 || !email.includes("@")) {
      return errorNotify("Email is required");
    }
    try {
      const formData = new FormData();
      formData.append("email", email);
      const res = await fetch(`${baseUrl.url}/subscribe`, {
        method: "POST",
        headers: {
          Authorization: baseUrl.token,
        },
        body: formData,
      });
      const resData = await res.json();
      if (resData.status) {
        successNotify("Email subscribed successfully");
        setEmail("")
      } else {
        errorNotify(resData?.errors[0]);
      }
    }
    catch (e) {
      console.error(e)
    }
  };

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={50000}
        hideProgressBar={false}
        className="shadow-none"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <SectionWrapper>
        <div>
          <div
              className=" w-full h-[307px] lg:h-[307px]  flex justify-center items-center bg-slate-100 dark:bg-gray-800"
              style={backgroundStyle}>
            <div>
              <div>
                <h1 className="sm:text-3xl text-xl font-bold text-black  mb-2 text-center">
                  Never <span className="mx-1 text-yellow-500">Miss </span> a Beat

                </h1>
                <p className="text-center text-sm font-medium text-black  ">

                  Subscribe to Our Newsletter for Exclusive Updates
                </p>
              </div>

              <div

                  className="sm:w-[543px] w-[300px] h-[54px] flex mt-8"
              >
                <div
                    className="flex-1 bg-white pl-4 flex space-x-2 items-center h-full focus-within:text-yellow text-black">
                <span>
                  <AiOutlineMail size={20}/>
                </span>
                  <input
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      value={email}
                      className="w-full h-full focus:outline-none text-sm placeholder:text-xs placeholder:text-black text-black font-normal tracking-wider"
                      placeholder="Type Your Email Address"
                  />
                </div>
                <Button size={"md"} title={"Subscribe"} func={handleSubmit}/>
              </div>
            </div>
          </div>
        </div>
      </SectionWrapper>

    </div>
  );
};

export default Subscription;
