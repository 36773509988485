import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../Hooks/Helper";
import useStore from "../../../Hooks/useStore";
import ProductCard from "../../Cards/ProductCard/ProductCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/bundle";
import { BsPatchCheckFill } from "react-icons/bs";
// import { fetchHomeData } from "../../../Redux/Slices/rootSlice/rootSlice";
const Promo = () => {
  const [promos, setPromos] = useState([]);
 

  const [products, setProducts] = useState([]);
  const [promoDetails, setPromoDetails] = useState({});
  const { customerToken, customerInfo } = useStore().data;
  const verified = customerInfo?.user_data?.email_verified_at
  useEffect(() => {
    if(!verified){
      return
    }
    const getPromo = async () => {
      try {
        const res = await fetch(`${baseUrl.url}/user/available-promo-codes`, {
          method: "GET",
          headers: {
            Authorization: `bearer ${customerToken}`,
          },
        });
        const resData = await res.json();
        if (resData.status) {
          setPromos(resData.data);
         
        }
      } catch (error) {
        console.error("error", error);
      }
    };
    getPromo().then();
  }, [customerToken, verified]);





  const [selectedPromoIndex, setSelectedPromoIndex] = useState(null);
  const handleButtonClick = (promo, index) => {
    setProducts(promo?.products);
    setPromoDetails(promo);
    setSelectedPromoIndex(index);
  };

 
  return (
    <div>
      <h5 className="text-slate-700 text-xl font-bold dark:text-white mt-4">
        Available promo code
      </h5>
      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-12 lg:col-span-3">
          <div className="grid grid-cols-1">
            {/* {promos?.map((promo, i) => {
               return (
                <button
                onClick={() => {setProducts(promo?.products)
                setPromoDetails(promo)
                }}
                className={`text-decoration-none p-2 tracking-wider text-sm text-start dark:bg-slate-700 dark:text-white bg-blue-300 border`}
                key={i}
              >
                {promo?.title}
              </button>
               );
            })} */}

            {promos?.map((promo, i) => (
              <div key={i} className="grid grid-cols-12 items-center">
                <div className="col-span-2 ">
                  <span className="font-bold dark:text-white">{i + 1}.</span>
                </div>
                <div className="col-span-10">
                  <button
                    onClick={() => handleButtonClick(promo, i)}
                    className={`text-decoration-none p-2 tracking-wider text-sm text-start w-full ${
                      selectedPromoIndex === i
                        ? "dark:bg-slate-500 bg-slate-100"
                        : "bg-transparent"
                    }  ${
                      selectedPromoIndex === i
                        ? "text-black"
                        : "text-gray-700 dark:text-white"
                    }`}
                    key={i}
                  >
                    {/* {promo.title} */}
                    {promo.code}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {promoDetails?.id ? (
          promoDetails?.is_global_product === 0 ? (
            <div className="col-span-12 lg:col-span-9">
              <div className="">
                {/* {products?.map((product, i) => {
                  return <ProductCard product={product} key={i} />;
                })} */}

        <Swiper
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
          }}
           autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
        loop={true}
        modules={[ Autoplay]}
        >
          {products?.length > 0 &&
            products?.map((product, i) => (
              <SwiperSlide key={i}>
                   <ProductCard product={product} key={i} promo={false} />
               
              </SwiperSlide>
            ))}
        </Swiper>
              </div>
            </div>
          ) : (
            <div className="col-span-12 lg:col-span-9">
               <div className="flex items-center justify-center mb-2">
            <BsPatchCheckFill size={50} className="text-green-500" />
          </div>
              <p className="flex items-center justify-center dark:text-white">
                Promo Code apply for all products
              </p>
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};

export default Promo;





     