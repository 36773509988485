import React, { createContext } from "react";
import useData from "../Hooks/useData";
import useProvider from "../Hooks/useProvider";

export const AuthContext = createContext();

const GlobalProvider = ({ children }) => {
  const data = useData()
  const allContent = useProvider();
  return (
    <AuthContext.Provider value={{store:allContent,data:data}}>{children}</AuthContext.Provider>
  );
};

export default GlobalProvider;