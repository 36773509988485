import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {HiBars3CenterLeft} from "react-icons/hi2";
import {BiUser} from "react-icons/bi";
import {RxCross1} from "react-icons/rx";
import useStore from "../../../Hooks/useStore";
import {baseUrl, handleLogout, navItems} from "../../../Hooks/Helper";
import {useSpeechRecognition} from "react-speech-recognition";
import {useDispatch, useSelector} from "react-redux";
import {fetchCartList} from "../../../Redux/Slices/Cart/cartListSlice";
import {fetchAllwishList} from "../../../Redux/Slices/Wishlist/wishlistSlice";
import {AiFillFacebook, AiFillInstagram, AiOutlineShoppingCart,} from "react-icons/ai";
import {FaYoutube} from "react-icons/fa";
import {VscGitCompare} from "react-icons/vsc";
import {BsHeart} from "react-icons/bs";
import "./NavBar4.css";
import Button from "../../Button/Button";
import VisibleBranch from "../../VisibleBranch/VisibleBranch";

const Navbar4 = ({ staticMenus,flash }) => {
  const { transcript } = useSpeechRecognition();
  const navigate = useNavigate();
  const {
    setting,
    customerToken,
    secondaryToken,
    customerInfo,
    setViewAllFlashSell,
    setDiscount,
    setFeatureCat,
    setSubCategoryId,
    setCategoryId,
    setMaxPrice,
    setMinPrice,
    refreshAll,
    setRefreshAll,
    setProductName,
    update,
    setCustomerToken,
    compare,
    darkMode,
    selectedBranch,

  } = useStore().data;
  const [showSideBar, setShowSideBar] = useState(false);
  const location = useLocation().pathname;
  const [searchTerm, setSearchTerm] = useState("");
  const [sticky, setSticky] = useState(false);
  const [visibleBranch, setVisibleBranch] = useState(false);
  const {
    logo,
    dark_logo,
    facebook,
    instagram,
    youtube,
  } = setting;

  //cart items
  const dispatch = useDispatch();
  const { cartItems, updates } = useSelector((state) => state?.cartItems);
  useEffect(() => {
    dispatch(fetchCartList({ customerToken, secondaryToken }));
  }, [dispatch, updates, customerToken, secondaryToken, update]);

  const { wishlist } = useSelector((state) => state?.wishlist);
  const verified = customerInfo?.user_data?.email_verified_at;

  useEffect(() => {
    if (!customerToken || !verified) {
      return;
    }
    if (customerToken) {
      dispatch(fetchAllwishList({ customerToken }));
    }
  }, [dispatch, customerToken, update, verified]);

  const handleSearchChange = (event) => {
    if (event.key === "Enter") {
      setProductName(searchTerm);
      navigate("/products");
    }
  };
  const handleSearchClick = () => {
    setProductName(searchTerm);
    navigate("/products");
  };


  useEffect(() => {
    if (transcript.length > 0) {
      setProductName(transcript);
      navigate("/products");
    }
  }, [transcript, navigate, setProductName]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        setSticky(true);
      } else if (scrolled <= 200) {
        setSticky(false);
      }
    });
  }, []);

  const newTabWithLink = (link) => {
    window.open(link);
  };

  const branchModalData = {visibleBranch, setVisibleBranch};

  // const innerWidth = window?.innerWidth;

  return (
    <div className={`navBar`}>
      <div
        className={`bg-gray-100 dark:bg-slate-700 py-1 ${
          sticky ? "sticky-nav  bg-white" : ""
        }`}
      >
        <VisibleBranch branchModalData={branchModalData} />
        <div className="container">
          <div className="grid grid-cols-12 ">
            <div className={`col-span-12 flex justify-between items-center`}>
              <div className="col-span-5 lg:col-span-6 flex justify-end">
                <ul className="flex justify-between m-0 cursor-pointers">
                  <li className="border-0 border-r px-1 lg:px-3 dark:text-white">
                    <div>
                      <AiFillFacebook
                        className="text-red-400"
                        size={20}
                        onClick={() => newTabWithLink(facebook)}
                      />
                    </div>
                  </li>

                  <li className="border-0 border-r px-1 lg:px-3 dark:text-white">
                    <div>
                      <FaYoutube
                        className="text-red-400"
                        size={20}
                        onClick={() => newTabWithLink(youtube)}
                      />
                    </div>
                  </li>

                  <li className=" px-1 lg:px-3 dark:text-white">
                    <div>
                      <AiFillInstagram
                        className="text-red-400"
                        size={20}
                        onClick={() => newTabWithLink(instagram)}
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-span-7 lg:col-span-6 hidden lg:block cursor-pointer " onClick={()=>setVisibleBranch(true)}>
                <span className="dark:text-white ">
                  {/*Please Call us to check the Stock before Placing the order!*/}
                  {selectedBranch?.name}
                </span>
              </div>
              <div className="col-span-6 block lg:hidden">
                <div className="flex items-center text-xs sm:text-sm gap-1 sm:gap-2 lg:gap-4 dark:text-white">
                  {/*<span>*/}
                  {/*  <IoCallOutline className="text-red-400"/>*/}
                  {/*</span>*/}
                  {/*<span className=" ">Hotline: {phone}</span>*/}
                  <span className="text-sm">{selectedBranch?.name}</span>
                </div>
              </div>

              <div>
                {customerToken && customerToken.length > 20 ? (
                  <button onClick={()=>handleLogout(customerToken,setCustomerToken,navigate())} className="text-sm dark:text-white ">
                    Logout
                  </button>
                ) : (
                  <Link to="/login" className="dark:text-white text-sm">
                    Login
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`dark:bg-slate-800 bg-white py-2 ${
          sticky ? "sticky-nav  bg-white" : ""
        }`}>
        <div className={"container"}>
          <div className="main-nav">
            <div
              className={"grid grid-cols-12 items-center justify-between gap-4"}
            >
              <div
                className={
                  "col-span-6 lg:col-span-3 items-center order-1 lg:order-1"
                }
              >
                <div className={""}>
                  <div className="grid grid-cols-12 items-center">
                    <div className="p-0 col-span-2 sm:block lg:hidden">
                      <HiBars3CenterLeft
                        onClick={() => setShowSideBar(true)}
                        size={30}
                        className="mr-2 text-gray-500 dark:text-white"
                      />
                    </div>
                    <div className="col-span-10 lg:col-span-12 p-0">
                      <div onClick={()=>{navigate('/')}} className="lg:w-[192px] lg:h-[106px] cursor-pointer">
                        {dark_logo || logo ? (
                          <img
                            className={"w-auto h-full"}
                            src={
                              darkMode
                                ? `${baseUrl.img}${dark_logo}`
                                : `${baseUrl.img}${logo}`
                            }
                            alt={"website logo"}
                          />
                        ) : (
                          <img
                            className={"w-4/4 lg:w-48 object-cover"}
                            src="https://placehold.co/192x106"
                            alt={"website logo"}
                          />
                        )}
                      </div >
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  "col-span-12 lg:col-span-6 order-3  lg:order-2 w-full"
                }
              >
                <div className="border border-slate-100 rounded flex justify-between mb-3 lg:mb-0">
                  <div
                    onKeyDown={handleSearchChange}
                    className="flex items-center"
                  >
                    <input
                      onChange={(e) => setSearchTerm(e.target.value)}
                      value={searchTerm || transcript}
                      type="text"
                      placeholder="Search Product"
                      className="w-full focus:outline-none border-0 text-sm rounded-0 bg-transparent primary-text-color px-2 dark:text-white"
                    />
                  </div>
                
                  <Button  size={"md"} title={"Search"}  func={handleSearchClick}/>
                </div>
              </div>
              {showSideBar && (
                <div
                  className="nav-menu-layout"
                  onClick={() => setShowSideBar(false)}
                ></div>
              )}
              <div
                className={
                  "col-span-6  lg:col-span-3 order-2  lg:order-3 flex items-center justify-end"
                }
              >
                <div className="grid grid-cols-12 items-center gap-3">
                  <div className="col-span-3 flex justify-end">
                    <div className="relative">
                      <div className="rounded-full cart flex items-center justify-end">
                        {customerToken && customerToken.length > 20 ? (
                          <Link
                            className="relative flex items-center"
                            to={"/wishlist"}
                          >
                            <BsHeart
                              className="primary-text-color dark:text-white text-gray-600"
                              size={20}
                            />
                            {wishlist?.length > 0 && (
                              <div className="absolute -top-2  left-3 bg- w-4 h-4 text-center text-xs rounded-full text-white">
                                {wishlist?.length}
                              </div>
                            )}
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-3 flex justify-end">
                    <div className="relative">
                      <div className="rounded-full cart flex items-center justify-end">
                        <Link
                          className="relative flex items-center"
                          to={"/compare"}
                        >
                          <VscGitCompare
                            className="primary-text-color dark:text-white text-gray-600"
                            size={20}
                          />
                          {compare?.length > 0 && (
                            <div className="absolute -top-2  left-3 bg-red-400 w-4 h-4 text-center text-xs rounded-full text-white">
                              {compare?.length}
                            </div>
                          )}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-3 flex justify-end">
                    <div className="relative">
                      <div className="rounded-full cart flex items-center justify-end">
                        <Link
                          className="relative flex items-center"
                          to={"/cart"}
                        >
                          <AiOutlineShoppingCart
                            className="primary-text-color dark:text-white text-gray-600"
                            size={20}
                          />
                          {cartItems?.length > 0 && (
                            <div className="absolute -top-2  left-3 bg-red-400 w-4 h-4 text-center text-xs rounded-full text-white">
                              {cartItems?.length}
                            </div>
                          )}
                        </Link>
                        <div className="cart-hover-item bg-white dark:bg-slate-800">
                          {cartItems?.length > 0 ? (
                            <div>
                              {cartItems?.slice(0, 5)?.map((cart, i) => (
                                <div key={i} className="grid grid-cols-12 mb-2">
                                  <div className="col-span-3">
                                    <img
                                      className="dark:bg-slate-900 rounded-lg"
                                      style={{ height: "50px", width: "50px" }}
                                      src={`${baseUrl.img}${cart?.product_combination?.product?.thumbnail_image}`}
                                      alt="cart"
                                    />
                                  </div>
                                  <div className="col-span-9">
                                    <p
                                      style={{ lineHeight: "15px" }}
                                      className="p-0 m-0 fw-bold font-medium dark:text-white"
                                    >
                                      {cart?.product_combination?.product?.name}
                                    </p>
                                    <p className="dark:text-white">
                                      {cart?.product_quantity} X ৳
                                      {flash &&
                                    cart?.product_combination?.product
                                      ?.is_on_sale === 1 ? (
                                      <span>
                                         {(cart?.product_combination
                                          ?.selling_price -
                                          (cart?.product_combination
                                            ?.selling_price * flash.discount) /
                                            100).toFixed(2)}
                                      </span>
                                    ) : (
                                      <span>
                                        {
                                          cart?.product_combination
                                            ?.selling_price
                                        }
                                      </span>
                                    )}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="p-3 text-center">
                              <div
                                style={{ width: "60px" }}
                                className="mx-auto"
                              >
                                <img
                                  src={require("../../../assets/cart/no-item.png")}
                                  alt=""
                                  className="w-full"
                                />
                              </div>
                              <p className="dark:text-white">No item in cart</p>
                            </div>
                          )}
                          <div>
                            <Link className="block" to={"/cart"}>
                            <Button classes={"w-full"} size={"md"} title={"View Cart"}/>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-3">
                    <div className="rounded-full cart flex items-center justify-end">
                      {customerToken && customerToken.length > 20 ? (
                        <Link
                          to={"/profile"}
                          className="text-decoration-none flex items-center justify-center"
                        >
                          <img
                            style={{ height: "20px", width: "20px" }}
                            className="rounded-full overflow-hidden"
                            src={
                              `${baseUrl.img}${customerInfo?.user_data?.profile?.image}` ||
                              "https://thumbs.dreamstime.com/b/beautiful-happy-woman-showing-love-sign-near-eyes-healthy-vision-portrait-holding-heart-shaped-hands-closeup-smiling-83939671.jpg"
                            }
                            alt=""
                          />
                        </Link>
                      ) : (
                        <Link
                          className="relative flex items-center"
                          to={"/login"}
                        >
                          <BiUser
                            className="primary-text-color dark:text-white text-gray-600"
                            size={20}
                          />
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`bg-white dark:bg-slate-800 border-t py-2 hidden lg:block `}
      >
        <div className="container">
          <div className="grid grid-cols-12">
            <div className="col-span-6">
              <ul
                className={
                  "m-0 p-0 sm:block md:flex lg:flex items-center  gap-10 roboto"
                }
              >
                {navItems?.map((item, i) => (
                  <li key={i}>
                    <button
                      className={`border-0 d-flex align-items-center text-sm ${
                        item.route === location
                          ? "active-nav-menu"
                          : "bg-transparent dark:text-white"
                      }`}
                      onClick={() => {
                        navigate(item.route);
                        setViewAllFlashSell(0);
                        setDiscount(0);
                        setFeatureCat(0);
                        setSubCategoryId(null);
                        setMinPrice(null);
                        setRefreshAll(!refreshAll);
                        setMaxPrice(null);
                        setCategoryId("");
                        setProductName("");
                      }}
                    >
                      {item?.icon}
                      <span className="ml-2 dark:text-white whitespace-nowrap">
                        {item.page}
                      </span>
                    </button>
                  </li>
                ))}
                {staticMenus?.map((item, i) => {
                  if (item.static_menu_type.name === "Footer") {
                    return null;
                  }
                  return (
                    <li key={i} className="mr-5">
                      <button
                        className={`border-0 d-flex align-items-center`}
                        onClick={() => {
                          navigate(`/page/${item.id}`);
                        }}
                      >
                        <span className="ml-2 dark:text-white whitespace-nowrap">
                          {item.menu_name}
                        </span>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-span-6 flex justify-end">
              <div className="flex items-center gap-4 dark:text-white">
                {/*<span>*/}
                {/*  <IoCallOutline />*/}
                {/*</span>*/}
                {/*<span className="text-sm">Hotline: {phone}</span>*/}
                <span className="text-sm">{selectedBranch?.name}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={` md:block lg:hidden transition-all duration-700 overflow-y-auto fixed top-0 z-50 h-full py-3  bg-white dark:bg-slate-800 w-52 ${
          showSideBar ? "ml-0" : "-ml-52"
        }`}
      >
        <div className="container">
          <div className="sm:block md:hidden text-end">
            <RxCross1
              onClick={() => setShowSideBar(false)}
              className="  dark:text-red-400"
            />
          </div>

          <ul
            className={
              "m-0 p-0 sm:block md:flex lg:flex items-center roboto mt-3"
            }
          >
            {navItems?.map((item, i) => (
              <li key={i} className="mr-5">
                <button
                  className={`border-none flex items-center ${
                    item.route === location
                      ? "active-nav-menu "
                      : "bg-transparent"
                  }`}
                  onClick={() => {
                    navigate(item.route);
                    setViewAllFlashSell(0);
                    setDiscount(0);
                    setFeatureCat(0);
                    setSubCategoryId(null);
                    setMinPrice(null);
                    setRefreshAll(!refreshAll);
                    setMaxPrice(null);
                    setCategoryId("");
                    setProductName("");
                    setShowSideBar(false);
                  }}
                >
                  {item?.icon}{" "}
                  <span className=" dark:text-white">{item.page}</span>
                </button>
              </li>
            ))}
            {staticMenus?.map((item, i) => {
              if (item.static_menu_type.name === "Footer") {
                return null;
              }
              return (
                <li key={i} className="mr-5">
                  <button
                    className={`border-none flex items-center ${
                      item.route === location
                        ? "active-nav-menu "
                        : "bg-transparent"
                    }`}
                    onClick={() => {
                      navigate(`/page/${item.id}`);
                      setViewAllFlashSell(0);
                      setDiscount(0);
                      setFeatureCat(0);
                      setSubCategoryId(null);
                      setMinPrice(null);
                      setRefreshAll(!refreshAll);
                      setMaxPrice(null);
                      setCategoryId("");
                      setProductName("");
                      setShowSideBar(false);
                    }}
                  >
                    {item?.icon}{" "}
                    <span className=" dark:text-white">{item.menu_name}</span>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar4;
