import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { baseUrl } from "../../../Hooks/Helper";


const initialState = {
    themeData: [],
    themeLoader: false,
    err: null
}
export const fetchThemeData = createAsyncThunk("fetchThemeData/theme", async() =>{
    const res = await fetch(`${baseUrl.url}/theme`, {
        method: 'GET',
        headers: {
            'Authorization':  baseUrl.token 
        },
    })
    const resData = await res.json();
    return resData.data
})

const rootSlice = createSlice({
    name: "themedata",
    initialState,
    extraReducers:(builder =>{
        builder.addCase(fetchThemeData.pending, state =>{
            state.themeLoader = true
        }).addCase(fetchThemeData.fulfilled, (state, action) =>{
            state.themeLoader = false
            state.themeData = action.payload
            state.err = null
        }).addCase(fetchThemeData.rejected, (state, action) =>{
            state.themeLoader = false
            state.themeData = []
            state.err = action.payload
        })
    })



})
export default  rootSlice.reducer;