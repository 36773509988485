import React from "react";
import { baseUrl } from "../../../Hooks/Helper";
const Slider5 = ({ banners }) => {
  return (
    <section className="pb-8 lg:pb-16">
      <div className="container">
        <div className="grid grid-cols-12 gap-3 mt-4">
          <div className="col-span-12 lg:col-span-3">
            <div className="grid grid-cols-12 gap-3">
              <div className="hidden md:block col-span-12">
                <div className="w-auto xl:w-[324px] h-[230px] ">
                  {banners?.length > 0 && banners[0]?.image ? (
                    <img
                      src={`${baseUrl.img}/${banners[0]?.image}`}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <img
                      src={"https://placehold.co/324x230"}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  )}
                </div>
              </div>
              <div className="hidden md:block col-span-12">
                <div className="w-auto xl:w-[324px] h-[230px]">
                  {banners?.length > 0 && banners[1]?.image ? (
                    <img
                      src={`${baseUrl.img}/${banners[1]?.image}`}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <img
                      src={"https://placehold.co/324x230"}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-9">
            <div className="lg:w-[1004px] lg:h-[474px] ">
              {banners?.length > 0 && banners[2]?.image ? (
                <img
                  src={`${baseUrl.img}/${banners[2]?.image}`}
                  alt=""
                  className="w-full h-full object-cover"
                />
              ) : (
                <img
                  src={"https://placehold.co/1004x474"}
                  alt=""
                  className="w-full h-full object-cover"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Slider5;
