import React from "react";
import SectionHeading2 from "../../SectionHeadings/SectionHeading2/SectionHeading2";
import { baseUrl } from "../../../Hooks/Helper";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const Sponsor8 = ({ data }) => {
  const data2 = {
    heading: "Sponsors",
    paragraph: "A Special Thanks to Our Sponsors: Making It Possible",
  };
  return (
    <section>
      <SectionWrapper>
        <SectionHeading2 data={data2} />

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 items-center gap-8 mt-5">
          {data?.length > 0 ? (
              data.map((sponsor, i) => (
                  <div
                      className="bg-white h-[126px] w-full border dark:border-gray-800"
                      key={i}
                  >
                    <img
                        src={
                          sponsor?.image
                              ? `${baseUrl.img}/${sponsor.image}`
                              : "https://placehold.co/164x78"
                        }
                        alt="sponsors"
                        className="w-auto h-full mx-auto"
                    />
                  </div>
              ))
          ) : (
              <div className="bg-white h-[126px] w-full border dark:border-gray-800">
                <img
                    src="https://placehold.co/210x126"
                    alt="placeholder"
                    className="w-auto h-full mx-auto"
                />
              </div>
          )}
        </div>
      </SectionWrapper>
    </section>
  );
};

export default Sponsor8;
