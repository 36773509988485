import React from "react";
import ProductCard from "../../Cards/ProductCard/ProductCard";
import "./ProductContainer.css";

const ProductsContainer = ({data, flash}) => {
    return (
        <div className="container">
            {data?.length > 0 ? (
                <div className="grid grid-cols-2 lg:grid-cols-6 gap-4 mt-3">
                    {data?.map((product, i) => (
                        <div key={i}>
                            <ProductCard product={product} flash={product.is_on_sale === 1 ? flash : null}/>
                        </div>
                    ))}
                </div>
            ) : (
                <div className={""}>
                    <p className="min-h-[200px] flex items-center justify-center">
                        No similar products are available for this item.
                    </p>
                </div>
            )}
        </div>
    );
};

export default ProductsContainer;
