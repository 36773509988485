import React from "react";
import "swiper/css/navigation";
import "swiper/css/bundle";
import ProductCard from "../../Cards/ProductCard/ProductCard";


const ProductContainer4 = ({ data,flash }) => {

  return (
    <div>
      <div className="container">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
        {data?.slice(0, 12)?.map((product, i)=>{
          return <ProductCard key={i} product={product}  flash={product.is_on_sale===1 ? flash : null}/>
        })}
        </div>
      </div>
    </div>
  );
};

export default ProductContainer4;
