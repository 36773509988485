import React from 'react'
import ProductContainer2 from '../../ProductContainers/ProductContainer2/ProductContainer2'
import SectionHeading2 from '../../SectionHeadings/SectionHeading2/SectionHeading2'
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
// import ProductContainer2 from '../../ProductContainer2/ProductContainer2'

const PopularProducts2 = ({data, flash}) => {
    const heading = {
        heading: "Demanding Excellence",
        paragraph: "Discover What's Trending Now",
        show: data?.length > 7
    }
    return (
        <section>
            <SectionWrapper>
                <SectionHeading2 data={heading}/>
                <ProductContainer2 data={data} flash={flash}/>
            </SectionWrapper>
        </section>
    )
}

export default PopularProducts2