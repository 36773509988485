import React from 'react';
import ProductCard2 from './ProductCard2/ProductCard2';

const ProductContainer2 = ({data, flash}) => {
    return (
        <div
            className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mt-5">
            {
                data?.slice(0, 8)?.map((product, i) => <ProductCard2 key={i} product={product}
                                                                     flash={product.is_on_sale === 1 ? flash : null}/>)
            }
        </div>
    );
};

export default ProductContainer2;