import React from "react";
import { useNavigate } from "react-router-dom";
import CoutDown from "../CountDowns/CoutDown/CoutDown";

const SideImage = ({ title, text, image, background, data }) => {
const navigate=useNavigate()
  return (
    <div
      className={`col-span-12 lg:col-span-3 ${background} rounded-none lg:rounded-lg overflow-hidden flash-sale-1 dark:bg-gray-900  min-h-fit lg:min-h-[450px] p-4 lg:p-4`}
    >
      <div>
        <h1 className="font-bold text-xl lg:text-2xl text-gray-700 dark:text-white">{title}</h1>
        <p className="my-2 lg:my-2 text-slate-600 text-xs lg:text-base dark:text-gray-200 poppin">
          {text}
        </p>
        {
          data?.length > 7 &&     <button className="border px-2 lg:px-4 py-1 lg:py-2 bg-gray-200 hover:bg-gray-200 text-gray-900 text-sm" onClick={()=>navigate('/products')}>
          View all
        </button>
        }
    

        {data?.data && (
          <div className="mt-6">
            <CoutDown endDate={data?.endDate} data={data?.data} />
          </div>
        )}
        <div className="w-full hidden  xl:block lg:block mt-2">
          {
            image ? <img src={image} alt="" className="w-full h-full object-cover" />: <img src={ "https://placehold.co/243x340"} alt="" className="w-full h-full object-cover" />
          }
        </div>
      </div>
    </div>
  );
};

export default SideImage;
