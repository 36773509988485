import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { baseUrl } from "../../../Hooks/Helper"
const initialState = {
    area:[],
    loading:false,
    err:null
}
export const getAreas = createAsyncThunk("fetchArea/area",async(data)=>{
    const res = await fetch(`${baseUrl?.eCourier_base_url}/area-list`,{
        method: "POST",
        headers: {
            'Content-type': "application/json",
            'API-SECRET': baseUrl?.eCourier_api_secret,
            'API-KEY': baseUrl?.eCourier_api_key,
            'USER-ID': baseUrl?.eCourier_user_id
        },
        body: JSON.stringify(data)
    })
    const resData = await res.json()
    return resData.message
})

const fetchUnion = createSlice({
    name:'Area',
    initialState,
    extraReducers:(builder)=>{
        builder.addCase(getAreas.pending,(state)=>{
            state.loading = true;
        })
        builder.addCase(getAreas.fulfilled,(state,action)=>{
            state.loading = false;
            state.area = action.payload;
            state.err = null
        })
        builder.addCase(getAreas.rejected,(state,action)=>{
            state.loading = false;
            state.area = [];
            state.err = action.error.message
        })
    }
})
export default fetchUnion.reducer