import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { baseUrl } from "../../../Hooks/Helper";


const initialState = {
    data: {},
    loading: false,
    err: null
}

export const fetchHomeData = createAsyncThunk("fetchAllData/home", async() =>{
    const res = await fetch(`${baseUrl.url}/home`, {
        method: 'GET',
        headers: {
            'Authorization':  baseUrl?.token 
        },
    })
    const resData = await res.json();
    return resData.data
})

const rootSlice = createSlice({
    name: "homedata",
    initialState,
    extraReducers:(builder =>{
        builder.addCase(fetchHomeData.pending, state =>{
            state.loading = true
        }).addCase(fetchHomeData.fulfilled, (state, action) =>{
            state.loading = false
            state.data = action.payload
            state.err = null
        }).addCase(fetchHomeData.rejected, (state, action) =>{
            state.loading = false
            state.data = {}
            state.err = action.payload
        })
    })



})
export default  rootSlice.reducer;