import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "./FlashSale3.css";
import "swiper/css/bundle";
import ProductCard from "../../Cards/ProductCard/ProductCard";
import SectionHeading3 from "../../SectionHeadings/SectionHeading3/SectionHeading3";
import CoutDown from "../../CountDowns/CoutDown/CoutDown";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const FlashSale3 = ({data, flash}) => {
    const endDate = new Date(flash?.end_date);
    return (
        <section>
            <SectionWrapper>
                {data?.length > 0 && (
                    <div>
                        <SectionHeading3
                            title={"Flash Sale"}
                            text={"Scoring Big on Flash Sale Products"}
                        />
                        <div className="w-full lg:w-3/12 mx-auto">
                            <CoutDown endDate={endDate} data={data}/>
                        </div>
                        <div>
                            <div>
                                <div className="mt-3">
                                    <Swiper
                                        slidesPerView={2}
                                        spaceBetween={10}
                                        breakpoints={{
                                            640: {
                                                slidesPerView: 3,
                                                spaceBetween: 10,
                                            },
                                            768: {
                                                slidesPerView: 4,
                                                spaceBetween: 40,
                                            },
                                            1024: {
                                                slidesPerView: 6,
                                                spaceBetween: 10,
                                            },
                                        }}
                                        className="mySwiper"
                                    >
                                        {data?.map((product, i) => {
                                            return (
                                                <SwiperSlide key={i} className="">
                                                    <ProductCard
                                                        product={product}
                                                        flash={flash ? flash : null}
                                                    />
                                                </SwiperSlide>
                                            );
                                        })}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </SectionWrapper>
        </section>
    );
};

export default FlashSale3;
