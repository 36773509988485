import React from "react";

const AllProductLoader = () => {
  return (
    <div
      style={{ height: "60vh" }}
      className="w-full flex items-center justify-center"
    >
      <div className="w-16 h-16">
        <img
          src="https://i.gifer.com/ZKZg.gif"
          className="w-full h-full"
          alt=""
        />
      </div>
    </div>
  );
};

export default AllProductLoader;
