import React from "react";

const PageHeading = ({ h1, h2, h3 }) => {
  return (
    <div className="text-center">
      <h4 className="dark:text-white">{h1}</h4>
      <h1 className="text-2xl lg:text-4xl font-bold dark:text-white">{h2}</h1>
      <h4 className="dark:text-white">{h3}</h4>
    </div>
  );
};

export default PageHeading;
