import React from "react";
import CompareProducts from "../CompareProducts/CompareProducts";
import Subscription2 from "../../Components/Subscriptions/Subscription2/Subscription2";

const ComparePage = () => {
  return <div>
    <CompareProducts />
    <Subscription2/>
  </div>;
};

export default ComparePage;
