import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { baseUrl } from "../../../Hooks/Helper";


const initialState = {
    brands: [],
    loading: false,
    err: null,
    updates: false,
}

export const fetchAllBrand = createAsyncThunk("fetchBrand/brand", async() =>{
    const res = await fetch(`${baseUrl.url}/brand`, {
        method: 'GET',
        headers: {
            'Authorization':  baseUrl?.token 
        },
    })
    const resData = await res.json();
    return resData.data
})

const brandSlice = createSlice({
    name: "brand",
    initialState,
    extraReducers:(builder =>{
        builder.addCase(fetchAllBrand.pending, state =>{
            state.loading = true
        }).addCase(fetchAllBrand.fulfilled, (state, action) =>{
            state.loading = false
            state.brands = action.payload
            state.err = null
        }).addCase(fetchAllBrand.rejected, (state, action) =>{
            state.loading = false
            state.brands = []
            state.err = action.payload
        })
    })



})
export default  brandSlice.reducer;