import React from "react";
import "./Slider4.css";
import { Image, Swiper } from "react-nivo-slider";
import "react-nivo-slider/es/style";
import "react-nivo-slider/es/style/default";
import { baseUrl } from "../../../Hooks/Helper";
const Slider4 = ({ banners }) => {
  return (
    <div>
      {banners?.length > 0 ? (
        <Swiper
          style={{ boxShadow: "none" }}
          animSpeed={1000}
          pauseTime={4000}
          manualAdvance={true}
          transition="boxRain"
        >
          {banners?.map((banner, i) => {
            return (
              <Image
                key={i}
                src={`${baseUrl.img}${banner?.image}`}
                alt={`image${i}`}
              />
            );
          })}
        </Swiper>
      ) : (
        <Swiper
          style={{ boxShadow: "none" }}
          animSpeed={1000}
          pauseTime={4000}
          manualAdvance={true}
          transition="boxRain"
        >
          {Array.from({ length: 5 }).map((_, i) => {
            return <Image key={i} src={"https://placehold.co/1905x1195"} />;
          })}
        </Swiper>
      )}
    </div>
  );
};

export default Slider4;
