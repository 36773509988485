import React from "react";
import { baseUrl } from "../../../Hooks/Helper";
import { Link } from "react-router-dom";
// import { AiFillStar } from "react-icons/ai";
import useStore from "../../../Hooks/useStore";
import { BsArrowRight } from "react-icons/bs";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

const ProductCard5 = ({ data, flash }) => {
  const { showDummyImage } = useStore().data;
  const {
    id,
    name,
    thumbnail_image,
    slug,
    inventories_sum_stock_quantity,
    category,
    product_review_rating,
    display_price,
    previous_display_price,
  } = data;
  const discount = +previous_display_price - +display_price;
  const flashSalemoney = (display_price * flash?.discount) / 100;
  return (
    <div className="group">
      <div className={`overflow-hidden text-dark`}>
        <div className="relative">
          {flash ? (
            <div className="absolute top-5 bg-gray-100 right-0 text-sm z-30 pr-2 flex">
              <div className="h-5 w-10 text-center bg-red-400 mr-2 text-white ">
                {flashSalemoney}৳
              </div>
              less
            </div>
          ) : discount > 0 ? (
            <div className="absolute top-5 bg-gray-100 right-0 text-sm z-30 pr-2 flex">
              <div className="h-5 w-10 text-center bg-red-400 mr-2 text-white ">
                {discount}৳
              </div>
              less
            </div>
          ) : (
            ""
          )}
          <Link
            to={`/details/${id}/${slug}`}
            className={``}
          >
            {thumbnail_image ? (
             <div className="bg-slate-100 dark:bg-slate-800 block overflow-hidden h-[230px] w-full">
               <img
                loading="lazy"
                onError={({ currentTarget }) =>
                  showDummyImage({ currentTarget })
                }
                src={`${baseUrl.img}${thumbnail_image}`}
                alt=""
                className={`w-full rounded group-hover:scale-105  transition-all duration-500 h-full`}
              />
             </div>
            ) : (
              <img
                src={"https://placehold.co/324x250"}
                alt=""
                className={`w-full rounded group-hover:scale-105  transition-all duration-500 h-full object-cover`}
              />
            )}
          </Link>
          <div className="p-2 grid grid-cols-12">
            <div className="col-span-6">
              {category && (
                <p className="inline text-xs text-slate-700 dark:text-white">
                  {category.name}
                </p>
              )}
            </div>
            <div className="col-span-6 text-end">
              {flash ? (
                <p className="text-gray-700 dark:text-white font-medium tracking-tight text-lg">
                  ৳ {display_price - flashSalemoney}
                </p>
              ) : (
                <p className="text-gray-700 dark:text-white font-medium tracking-tight text-lg">
                  ৳ {display_price}
                </p>
              )}
            </div>
          </div>
          <div className="text-center px-2">
            <div className="flex justify-between gap-2">
              <Link to={`/details/${id}/${slug}`}>
                <h3 className="block text-base text-slate-900 dark:text-sky-500 poppin">
                  {name?.length > 20 ? name?.slice(0, 20) + "..." : name}
                </h3>
              </Link>

              {inventories_sum_stock_quantity > 0 ? (
                <p className="text-green-500 font-normal text-xs whitespace-nowrap">
                  In Stock
                </p>
              ) : (
                <p className="text-red-500 font-normal text-xs whitespace-nowrap">
                  Out of Stock
                </p>
              )}
            </div>
            <p className="mb-0 flex items-center">
              {Array.from({ length: 5 }).map((_, i) => {
                if (i < parseInt(product_review_rating[0]?.avg_review_rating)) {
                  return <AiFillStar key={i} className="text-orange-500" />;
                }
                return <AiOutlineStar key={i} className="text-gray-400" />;
              })}
            </p>
            <div className="flex items-center mt-2 justify-center -ml-4 opacity-0 group-hover:opacity-100 group-hover:ml-0 transition-all duration-300">
              <Link
                to={`/details/${id}/${slug}`}
                className="flex items-center font-bold text-red-400"
              >
                Add to cart <BsArrowRight />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard5;
