import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { baseUrl } from "../../../Hooks/Helper";


const initialState = {
    featureProduct: [],
    loading: false,
    err: null,
    updates: false,
}
export const fetchAllfeatureProduct = createAsyncThunk("fetchfeatureProduct/featureProduct", async() =>{
    const res = await fetch(`${baseUrl.url}/product-on-featured`, {
        method: 'GET',
        headers: {
            'Authorization':  baseUrl?.token 
        },
    })
    const resData = await res.json();
    if(res.ok){
        return resData.product
    }
    return []
})

const featureProductSlice = createSlice({
    name: "featureProduct",
    initialState,
    extraReducers:(builder =>{
        builder.addCase(fetchAllfeatureProduct.pending, state =>{
            state.loading = true
        }).addCase(fetchAllfeatureProduct.fulfilled, (state, action) =>{
            state.loading = false
            state.featureProduct = action.payload
            state.err = null
        }).addCase(fetchAllfeatureProduct.rejected, (state, action) =>{
            state.loading = false
            state.featureProduct = []
            state.err = action.payload
        })
    })



})
export default  featureProductSlice.reducer;