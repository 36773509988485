import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { baseUrl } from "../../../Hooks/Helper";


const initialState = {
    flashSale: [],
    loading: false,
    err: null,
    updates: false,
}

export const fetchAllflashSale = createAsyncThunk("fetchflashSale/flashSale", async() =>{
    const res = await fetch(`${baseUrl.url}/product-on-sell`, {
        method: 'GET',
        headers: {
            'Authorization':  baseUrl?.token 
        },
    })
    const resData = await res.json();
    if(res.ok){
        return resData.product.data
    }
    return []
})

const flashSaleSlice = createSlice({
    name: "flashSale",
    initialState,
    extraReducers:(builder =>{
        builder.addCase(fetchAllflashSale.pending, state =>{
            state.loading = true
        }).addCase(fetchAllflashSale.fulfilled, (state, action) =>{
            state.loading = false
            state.flashSale = action.payload
            state.err = null
        }).addCase(fetchAllflashSale.rejected, (state, action) =>{
            state.loading = false
            state.flashSale = []
            state.err = action.payload
        })
    })



})
export default  flashSaleSlice.reducer;