import React from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./FeatureProduct2.css";
import "react-toastify/dist/ReactToastify.css";
import SectionHeading2 from "../../SectionHeadings/SectionHeading2/SectionHeading2";
import ProductContainer2 from "../../ProductContainers/ProductContainer2/ProductContainer2";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const FeatureProduct2 = ({data, flash}) => {

    const heading = {
        heading: "Featured Products",
        paragraph: "Unveiling the Best in Innovative Products",
        show: data?.length > 7
    }

    return (
        <section>
            <SectionWrapper>
                <SectionHeading2 data={heading}/>
                <ProductContainer2 data={data} flash={flash}/>
            </SectionWrapper>
        </section>
    );
};

export default FeatureProduct2;
