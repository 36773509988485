import React from "react";
import CheckoutContainer2 from "../../Components/PageContainers/CheckoutContainer/CheckoutContainer2";
import { CiDeliveryTruck } from "react-icons/ci";
import { BsCashCoin } from "react-icons/bs";
import { TbArrowBigLeftLinesFilled } from "react-icons/tb";
import { BiSupport } from "react-icons/bi";

const CheckoutPage = () => {
  return (
    <>
      <CheckoutContainer2 />
      <div className="py-8 lg:py-16">
        <div className="container">
          <div className="grid bg-white dark:bg-slate-800 grid-cols-1 lg:grid-cols-4 p-4 gap-1  ">
            <div className="mb-2 lg:mb-0 border-none lg:border-r ">
              <div className="flex gap-2 items-center">
                <div className="">
                  <div className="w-12 h-12 flex items-center justify-center shadow rounded-full bg-cyan-500 text-white">
                    <CiDeliveryTruck size={22} />
                  </div>
                </div>
                <div className="">
                  <span className="text-sm text-slate-900 dark:text-white">
                    Standard Delivery
                  </span>
                  <p className="text-xs text-slate-500 dark:text-slate-200">
                    Standard delivery system available
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-2 lg:mb-0 border-none lg:border-r pl-0 lg:pl-2">
              <div className="flex items-center gap-2">
                <div className="">
                  <div className="w-12 h-12 flex items-center justify-center shadow rounded-full bg-violet-500 text-white">
                    <BsCashCoin size={17} />
                  </div>
                </div>
                <div className="">
                  <span className="text-sm text-slate-900 dark:text-white">
                    Cash on Delivery Available
                  </span>
                  <p className="text-xs text-slate-500 dark:text-slate-200">
                    Cash on delivery feature is available for now.
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-2 lg:mb-0 border-none lg:border-r pl-0 lg:pl-2">
              <div className="flex items-center gap-2">
                <div className="">
                  <div className="w-12 h-12 flex items-center justify-center shadow rounded-full bg-pink-500 text-white">
                    <TbArrowBigLeftLinesFilled size={17} />
                  </div>
                </div>
                <div className="">
                  <span className="text-sm text-slate-900 dark:text-white">
                    Order Cancel Policy
                  </span>
                  <p className="text-xs text-slate-500 dark:text-slate-200">
                  Order cancellation policy available
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-2 lg:mb-0 border-none lg:border-r pl-0 lg:pl-2">
              <div className="flex items-center gap-2">
                <div className="">
                  <div className="w-12 h-12 flex items-center justify-center shadow rounded-full bg-teal-500 text-white">
                    <BiSupport size={17} />
                  </div>
                </div>
                <div className="">
                  <span className="text-sm text-slate-900 dark:text-white">
                    Custommer Support
                  </span>
                  <p className="text-xs text-slate-500 dark:text-slate-200">
                    24/7 Hour custommer support available
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="pl-0 lg:pl-2">
              <div className="flex items-center gap-2">
                <div className="">
                  <div className="w-12 h-12 flex items-center justify-center shadow rounded-full bg-orange-500 text-white">
                    <AiOutlineTransaction size={17} />
                  </div>
                </div>
                <div className="">
                  <span className="text-sm text-slate-900 dark:text-white">
                    Multiple payments
                  </span>
                  <p className="text-xs text-slate-500 dark:text-slate-200">
                    Multiple payment system available
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutPage;
