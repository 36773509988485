import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/bundle";
import SectionHeading5 from '../../Components/SectionHeadings/SectionHeading5/SectionHeading5';
import { baseUrl } from '../../Hooks/Helper';
const Brand = ({brands}) => {
    const data2 = {
        subheading: "Here is our",
        heading: "Brands",
        paragraph: "Recognizing Your choice",
      };

  return (
    <section className="py-8 lg:py-16">
      {
        brands?.length>0 &&  <div className="container">
        <SectionHeading5 data={data2} />
          <div className='mt-3'>
            <Swiper
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
              }}
               autoplay={{
                delay: 5500,
                disableOnInteraction: false,
              }}
            loop={true}
            modules={[ Autoplay]}
            >
              {brands?.length > 0 &&
                brands?.map((brand, i) => (
                  <SwiperSlide key={i}>
                    <div className='h-[126px] w-full'>
                      <img
                       src={`${baseUrl.img}/${brand?.image}` ||"https://placehold.co/219x105"}
                        alt="slider"
                        className="w-auto h-full object-cover"
                      />
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      }
   
  </section>
  )
}

export default Brand