import React from "react";
import "./DiscountProducts.css";
import SectionHeading2 from "../../SectionHeadings/SectionHeading2/SectionHeading2";
import ProductContainer2 from "../../ProductContainers/ProductContainer2/ProductContainer2";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const DiscountProduct = ({data, flash}) => {
    const heading = {
        heading: "Celebrate Savings",
        paragraph: " Dive into Our Discounted Delights!",
        show: data?.length > 7
    };
    return (
        <section>
            <SectionWrapper>
                {data?.length > 0 && (
                    <div>
                        <SectionHeading2 data={heading}/>
                        <ProductContainer2 data={data} flash={flash}/>
                    </div>
                )}
            </SectionWrapper>
        </section>
    );
};

export default DiscountProduct;
