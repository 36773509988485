import React from "react";
import { Link } from "react-router-dom";
import useStore from "../../../Hooks/useStore";
import { baseUrl } from "../../../Hooks/Helper";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
const ProductCard2 = ({ product, flash }) => {
  const { showDummyImage } = useStore().data;
  const {
    id,
    product_review_rating,
    slug,
    name,
    thumbnail_image,
    inventories_sum_stock_quantity,
    display_price,
    previous_display_price,
  } = product;
  const discountPercentage =
    ((previous_display_price - display_price) / previous_display_price) * 100;
  const flashSalemoney = (display_price * flash?.discount) / 100;

  return (
    <div className="relative rounded-lg overflow-hidden bg-white dark:bg-slate-800 shadow-sm">
      <div>
        <div className="grid grid-cols-12 items-center product-card2-text-container ">
          <div className="col-span-12 lg:col-span-4">
            <Link to={`/details/${id}/${slug}`} className="p-2 rounded block w-full h-full">
              {thumbnail_image ? (
               <div className="h-24 w-full">
                 <img
                  onError={({ currentTarget }) =>
                    showDummyImage({ currentTarget })
                  }
                  src={`${baseUrl.img}${thumbnail_image}`}
                  alt=""
                  className="w-auto h-full bg-slate-100 dark:bg-slate-900 rounded-lg"
                />
               </div>
              ) : (
                <img
                  src={"https://placehold.co/95x95"}
                  alt=""
                  className="w-full  bg-slate-100 dark:bg-slate-900 rounded-lg"
                />
              )}
            </Link>
          </div>
          <div className="col-span-12 lg:col-span-8">
          <Link to={`/details/${id}/${slug}`}>
          <h6 className="text-sm text-slate-800 dark:text-sky-500 tracking-wider">
              {name?.length > 20 ? name?.slice(0, 20) + "..." : name}
            </h6>
          </Link>
           
            <div className="flex">
              {flash ? (
                <p className="text-gray-700 font-semibold dark:text-white">
                  ৳ {display_price - flashSalemoney}
                </p>
              ) : (
                <p className="text-gray-700 font-semibold dark:text-white">
                  ৳ {display_price}
                </p>
              )}

              {previous_display_price && (
                <span className="text-slate-400 dark:text-red-400 ms-3">
                  <del>৳{previous_display_price}</del>
                </span>
              )}
            </div>
            <p className="mb-0 flex items-center">
              {Array.from({ length: 5 }).map((_, i) => {
                if (i < parseInt(product_review_rating[0]?.avg_review_rating)) {
                  return <AiFillStar key={i} className="text-orange-500" />;
                }
                return <AiOutlineStar key={i} className="text-gray-400" />;
              })}
            </p>
            {flash ? (
              <p className="text-xs dark:text-red-500 font-bold">
                {flash?.discount.toFixed(0)}% discount available
              </p>
            ) : previous_display_price ? (
              <p className="text-xs dark:text-red-500 font-bold">
                {discountPercentage.toFixed(0)}% discount available
              </p>
            ) : (
              ""
            )}

            {inventories_sum_stock_quantity > 0 ? (
              <p className="text-green-500 font-normal text-xs">In Stock</p>
            ) : (
              <p className="text-red-500 font-normal text-xs">Out of Stock</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard2;
