import React from "react";
import Design01 from "../../Designs/Design01/Design01";

const SectionHeading3 = ({ title, text }) => {
  return (
    <section className="">
      <div className="max-w-[400px] mx-auto relative text-center">
        <Design01/>
        <h2 className="text-lg lg:text-2xl font-bold roboto text-gray-700 dark:text-white">
          {title}
        </h2>
        <p className="text-sm text-gray-600 dark:text-white">{text}</p>
      </div>
    </section>
  );
};

export default SectionHeading3;
