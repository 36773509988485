import React, { useEffect, useState } from "react";
import { baseUrl, errorNotify, successNotify } from "../../../Hooks/Helper";
import { FaRegTimesCircle } from "react-icons/fa";
import Button from "../../Button/Button";

const Subscription3 = ({ data }) => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");

  const handleClose = () => {
    setShow(false);
    const closeTimestamp = new Date().getTime();
    localStorage.setItem("subscriptionClosedTimestamp", closeTimestamp.toString());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email.length < 7 || !email.includes("@")) {
      return errorNotify("Email is required");
    }
    const formData = new FormData();
    formData.append("email", email);
    const res = await fetch(`${baseUrl.url}/subscribe`, {
      method: "POST",
      headers: {
        Authorization: baseUrl.token,
      },
      body: formData,
    });
    const resData = await res.json();
    if (resData.status) {
      successNotify("Email subscribed successfully");
      setEmail("");
      handleClose();
    } else {
      errorNotify(resData?.errors[0]);
    }
  };

  useEffect(() => {
    const closeTimestamp = localStorage.getItem("subscriptionClosedTimestamp");
    if (!closeTimestamp || (new Date().getTime() - parseInt(closeTimestamp)) > 24 * 60 * 60 * 1000) {
      setTimeout(() => {
        setShow(true);
      }, 7000);
    }
  }, []);

  return (
    <div>
      {show && (
        <div>
          <div className="fixed top-0 left-0 z-40 h-screen w-full flex items-center justify-center">
            <div className="fixed inset-0 bg-slate-600 opacity-50"></div>
            <div className="bg-white dark:bg-gray-900 w-[500px] shadow relative">
              <FaRegTimesCircle
                onClick={handleClose}
                size={25}
                className="absolute top-2 right-2 cursor-pointer text-red-400"
              />
              <div className="h-36 w-full">
                {data?.newsletter_image ? (
                  <img
                    src={`${baseUrl.img}/${data?.newsletter_image}`}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <img
                    src={"https://placehold.co/500x144"}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                )}
              </div>
              <div className="p-8">
                <h2 className="text-2xl font-bold text-gray-800 dark:text-white">
                  Elevate Your Experience
                </h2>
                <h2 className="text-2xl font-bold text-gray-800 dark:text-white">
                  & Stay Ahead of the Curve
                </h2>
                <p className="my-6 text-gray-700 dark:text-gray-200">
                  Subscribe for a Front-Row Seat to Our News and Events.
                </p>
                <form onSubmit={handleSubmit} className="grid grid-cols-12">
                  <div className="col-span-9 bg-gray-100 dark:bg-gray-800">
                    <input
                      type="text"
                      placeholder="Your email here..."
                      className="w-full focus:outline-none p-2 bg-transparent dark:text-white"
                      name="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <Button  size={"md"} title={"Subscribe"} func={handleSubmit} classes={'col-span-3'}/>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Subscription3;
