import React from "react";
import ProductContainer4 from "../../ProductContainers/ProductContainer4/ProductContainer4";
import SectionHeading3 from "../../SectionHeadings/SectionHeading3/SectionHeading3";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
// import ProductContainer4 from "../../ProductContainer4/ProductContainer4";

const PopularProducts3 = ({ data ,flash}) => {

  return (
    <section>
        <SectionWrapper>
            <SectionHeading3 title={'In The Limelight'} text={'Trendsetting Products Worth Trying'} />
            <div className="mt-5">
                <ProductContainer4 data={data} flash={flash} />
            </div>
        </SectionWrapper>
    </section>
  );
};

export default PopularProducts3;
