import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { baseUrl } from "../../../Hooks/Helper";


const initialState = {
    about: [],
    loading: false,
    err: null,
    updates: false,
}

export const fetchAllabout = createAsyncThunk("fetchabout/about", async() =>{
    const res = await fetch(`${baseUrl.url}/about-us`, {
        method: 'GET',
        headers: {
            'Authorization':  baseUrl?.token 
        },
    })
    const resData = await res.json();
    if(res.ok){
        return resData.data.description
    }
    return []
})

const aboutSlice = createSlice({
    name: "about",
    initialState,
    extraReducers:(builder =>{
        builder.addCase(fetchAllabout.pending, state =>{
            state.loading = true
        }).addCase(fetchAllabout.fulfilled, (state, action) =>{
            state.loading = false
            state.about = action.payload
            state.err = null
        }).addCase(fetchAllabout.rejected, (state, action) =>{
            state.loading = false
            state.about = []
            state.err = action.payload
        })
    })



})

export default  aboutSlice.reducer;