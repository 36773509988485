import React from "react";
import "./DiscountProducts2.css";
import SideImage from "../../SideImage/SideImage";
import ProductCard from "../../Cards/ProductCard/ProductCard";
import { baseUrl } from "../../../Hooks/Helper";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
const DiscountProducts2 = ({ data, site_banners,flash }) => {
  const text =
    "Experience the thrill of savings like never before with our exclusive collection of discount products. From fashion, home essentials to gifts, we've curated a wide range of items that offer unbeatable value.";
  return (
    <section>
      <SectionWrapper>
        {data?.length > 0 && (
            <div>
              <div className="grid grid-cols-12 gap-4 mt-4">
                <SideImage
                    image={
                      site_banners?.discount_product_image
                          ? `${baseUrl.img}/${site_banners?.discount_product_image}`
                          : "https://placehold.co/243x306"
                    }
                    title={"Discount Products"}
                    text={text}
                    background={"bg-white dark:bg-gray-800"}
                />
                <div className="col-span-12 lg:col-span-9">
                  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-4">
                    {data?.length > 0 &&
                        data
                            ?.slice(0, 8)
                            ?.reverse()
                            ?.map((product, i) => (
                                <div key={i}>
                                  <ProductCard product={product} flash={product.is_on_sale===1 ? flash : null}/>
                                </div>
                            ))}
                  </div>
                </div>
              </div>
            </div>
        )}
      </SectionWrapper>
    </section>
  );
};

export default DiscountProducts2;
