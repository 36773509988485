import React from "react";
import { useNavigate } from "react-router-dom";
import ProductCard7 from "../../Cards/ProductCard7/ProductCard7";
import SectionHeading7 from "../../SectionHeadings/SectionHeading7/SectionHeading7";
// import CoutDown from '../../CountDowns/CoutDown/CoutDown';
import CountDown3 from "../../CountDowns/CountDown3/CountDown3";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const FlashSale10 = ({ data, flash }) => {
  const navigate = useNavigate();
  const goAllPage = () => {
    navigate("/products");
  };
  const pageHeading = {
    heading: "Flash Sale Frenzy",
    paragraph: "Grabbing Deals on Hot Products",
    action: goAllPage,
    show: data?.length > 7,
  };
  const endDate = new Date(flash?.end_date);
  return (
    <section>
      <SectionWrapper>
        {data?.length > 0 && (
            <div>
              <SectionHeading7 data={pageHeading} />
              <div className="w-full lg:w-3/12 mt-4">
                <CountDown3 endDate={endDate} data={data} />
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-3">
                {data?.slice(0, 10)?.map((product, i) => (
                    <div key={i}>
                      <ProductCard7 product={product} flash={flash ? flash : null} />
                    </div>
                ))}
              </div>
            </div>
        )}
      </SectionWrapper>
    </section>
  );
};

export default FlashSale10;
